
	// body
	 @ems-integration-body-background-start-color: @grey-6;
	 @ems-integration-body-background-end-color: @ems-integration-body-background-start-color;


	// footer height (only need for calculation)
	 @footer-height: 38px;


	// ems analysis
	 @ems-analysis-card-header-height: @main-process-top-stripe-height;
     @ems-analysis-card-header-background-color:  @color-1;
	 @ems-analysis-card-header-border-bottom: 1px solid @color-1;
	 @ems-analysis-card-header-font-family: @main-process-card-title-font-family;
	 @ems-analysis-card-header-font-weight: @main-process-card-title-font-weight;
	 @ems-analysis-card-header-font-size: @main-process-card-title-font-size;
	 @ems-analysis-card-header-color: @main-process-card-title-color;

@ems-analysis-card-header-toolbar-icon-color: @grey-1;
@ems-analysis-card-header-toolbar-icon-color-hover-focus: @color-2;  
	 @ems-analysis-card-header-toolbar-iconbtn-background-color: @color-2;
	 @ems-analysis-card-header-toolbar-iconbtn-icon-color: @white;
	 @ems-analysis-card-header-toolbar-iconbtn-background-color-hover-focus: @color-2;
/***/@ems-analysis-card-header-toolbar-iconbtn-icon-color-hover-focus: @color-1;


	// ems analysis filter
	 @ems-analysis-filter-container-initial-width: 30%;
	 @ems-analysis-filter-container-initial-width-md: 44%;
	 @ems-analysis-filter-container-order: 1;
	 @ems-analysis-filter-container-background-color: @grey-7;
	 @ems-analysis-filter-container-resizable-max-width: calc(~'50% - @{ems-analysis-filter-toggle-container-width}');
	 @ems-analysis-filter-container-resizable-min-width: calc(~'@{viewport-xs-min} + @{ems-analysis-filter-toggle-container-width}');

	 @ems-analysis-filter-content-container-order: 1;
	 @ems-analysis-filter-headline-sm-font-family: @font-bold;
	 @ems-analysis-filter-headline-sm-font-weight: @font-bold-weight;
	 @ems-analysis-filter-headline-sm-font-size: 18px;
	 @ems-analysis-filter-headline-sm-color: @color-2;
	 @ems-analysis-filter-toggle-container-background-color: @grey-5;
	 @ems-analysis-filter-toggle-container-width: 32px;
	 @ems-analysis-filter-toggle-container-order: 2;
	 @ems-analysis-filter-open-close-filter-background-color: @color-1;
	 @ems-analysis-filter-open-close-filter-height: 74px;
	 @ems-analysis-filter-open-close-filter-icon-color: @white;
	 @ems-analysis-filter-open-close-filter-background-color-hover: @color-2;
	 @ems-analysis-filter-open-close-filter-icon-color-hover: @white;

	 @ems-analysis-filter-button-primary-background-color: @button-primary-background-color;
	 @ems-analysis-filter-button-primary-border: @button-primary-border;
	 @ems-analysis-filter-button-primary-border-radius: @button-border-radius;
	 @ems-analysis-filter-button-primary-font-family: @font-regular;
	 @ems-analysis-filter-button-primary-font-weight: @font-regular-weight;
	 @ems-analysis-filter-button-primary-font-size: @button-primary-font-size;
	 @ems-analysis-filter-button-primary-color: @button-primary-color;
	 @ems-analysis-filter-button-primary-padding: @button-primary-padding;
	 @ems-analysis-filter-button-primary-background-color-hover-focus: @button-primary-background-color-hover-focus;
	 @ems-analysis-filter-button-primary-border-hover-focus: @button-primary-border-hover-focus;
	 @ems-analysis-filter-button-primary-color-hover-focus: @button-primary-color-hover-focus;

	 @ems-analysis-filter-button-primary-active-background-color: @color-1;
	 @ems-analysis-filter-button-primary-active-border: 2px solid @color-1;
	 @ems-analysis-filter-button-primary-active-font-family: @font-bold;
	 @ems-analysis-filter-button-primary-active-font-weight: @font-bold-weight;
	 @ems-analysis-filter-button-primary-active-font-size: @button-primary-font-size;
	 @ems-analysis-filter-button-primary-active-color: @color-2;
	 @ems-analysis-filter-button-primary-active-background-color-hover-focus: @ems-analysis-filter-button-primary-active-background-color;
	 @ems-analysis-filter-button-primary-active-border-hover-focus: @ems-analysis-filter-button-primary-active-border;
	 @ems-analysis-filter-button-primary-active-color-hover-focus: @ems-analysis-filter-button-primary-active-color;

	 @ems-analysis-filter-button-default-background-color: @grey-5;
	 @ems-analysis-filter-button-default-border: 2px solid @grey-5;
	 @ems-analysis-filter-button-default-border-radius: @button-border-radius;
	 @ems-analysis-filter-button-default-font-family: @font-regular;
	 @ems-analysis-filter-button-default-font-weight: @font-regular-weight;
	 @ems-analysis-filter-button-default-font-size: @button-default-font-size;
	 @ems-analysis-filter-button-default-color: @button-default-color;
	 @ems-analysis-filter-button-default-padding: @button-default-padding;
	 @ems-analysis-filter-button-default-background-color-hover-focus: @color-1;
	 @ems-analysis-filter-button-default-border-hover-focus: 2px solid @color-1;
	 @ems-analysis-filter-button-default-color-hover-focus: @button-default-color-hover-focus;

	 @ems-analysis-filter-button-default-active-background-color: @color-1;
	 @ems-analysis-filter-button-default-active-border: 2px solid @color-1;
	 @ems-analysis-filter-button-default-active-font-family: @font-bold;
	 @ems-analysis-filter-button-default-active-font-weight: @font-bold-weight;
	 @ems-analysis-filter-button-default-active-font-size: @button-default-font-size;
	 @ems-analysis-filter-button-default-active-color: @color-2;
	 @ems-analysis-filter-button-default-active-background-color-hover-focus: @ems-analysis-filter-button-default-active-background-color;
	 @ems-analysis-filter-button-default-active-border-hover-focus: @ems-analysis-filter-button-default-active-border;
	 @ems-analysis-filter-button-default-active-color-hover-focus: @ems-analysis-filter-button-default-active-color;

	 @ems-analysis-filter-indicator-object-background-color: @white;
	 @ems-analysis-filter-indicator-object-border-left: 12px solid @color-1;
	 @ems-analysis-filter-indicator-object-text-font-family: @font-bold;
	 @ems-analysis-filter-indicator-object-text-font-weight: @font-bold-weight;
	 @ems-analysis-filter-indicator-object-text-font-size: @font-size;
	 @ems-analysis-filter-indicator-object-text-color: @color-2;
	 @ems-analysis-filter-indicator-object-exchange-icon-color: @color-2;

	 @ems-analysis-filter-comparison-object-background-color: @white;
	 @ems-analysis-filter-comparison-object-border-left: 12px solid @color-1;
	 @ems-analysis-filter-comparison-object-text-font-family: @font-bold;
	 @ems-analysis-filter-comparison-object-text-font-weight: @font-bold-weight;
	 @ems-analysis-filter-comparison-object-text-font-size: @font-size;
	 @ems-analysis-filter-comparison-object-text-color: @font-color;
	 @ems-analysis-filter-comparison-object-close-icon-color: @color-2;

	 @ems-analysis-filter-interval-row-background-color: @grey-5;
	 @ems-analysis-filter-interval-row-calendar-icon-color: @font-color;
	 @ems-analysis-filter-interval-row-lock-icon-color: @font-color;
	 @ems-analysis-filter-interval-row-caret-icon-color: @color-2;
	 @ems-analysis-filter-theoretical-interval-row-caret-icon-color: @white;
	 @ems-analysis-filter-theoretical-interval-row-caret-icon-background-color: @color-2;
	 @ems-analysis-filter-theoretical-interval-row-caret-icon-border: 1px solid @color-2;
	 @ems-analysis-filter-theoretical-interval-row-caret-icon-border-radius: 3px;
	 @ems-analysis-filter-interval-row-caret-icon-inactive-color: @inactive-color;
	 @ems-analysis-filter-interval-row-close-icon-color: @color-2;

	 @ems-analysis-filter-interval-font-family: @font-regular;
	 @ems-analysis-filter-interval-font-weight: @font-regular-weight;
	 @ems-analysis-filter-interval-font-size: @font-size;
	 @ems-analysis-filter-interval-color: @color-2;

	 @ems-analysis-filter-add-interval-row-background-color: @grey-5;
	 @ems-analysis-filter-add-interval-row-border-top: 1px solid @white;
	 @ems-analysis-filter-add-interval-row-font-family: @font-regular;
	 @ems-analysis-filter-add-interval-row-font-weight: @font-regular-weight;
	 @ems-analysis-filter-add-interval-row-font-size: @font-size;
	 @ems-analysis-filter-add-interval-row-color: @color-2;

	 @ems-analysis-filter-add-object-row-background-color: @grey-5;
	 @ems-analysis-filter-add-object-row-border-top: 1px solid @white;
	 @ems-analysis-filter-add-object-row-font-family: @font-bold;
	 @ems-analysis-filter-add-object-row-font-weight: @font-bold-weight;
	 @ems-analysis-filter-add-object-row-font-size: @font-size;
	 @ems-analysis-filter-add-object-row-color: @color-2;

	 @ems-analysis-filter-accordion-header-background-color: @white;
	 @ems-analysis-filter-accordion-header-font-family: @font-bold;
	 @ems-analysis-filter-accordion-header-font-weight: @font-bold-weight;
	 @ems-analysis-filter-accordion-header-font-size: 18px;
	 @ems-analysis-filter-accordion-header-color: @color-2;
	 @ems-analysis-filter-accordion-header-border-left: 12px solid @color-1;
	 @ems-analysis-filter-accordion-body-background-color: @grey-5;

	 @ems-analysis-filter-subaccordion-header-background-color: @grey-5;
	 @ems-analysis-filter-subaccordion-header-font-family: @font-bold;
	 @ems-analysis-filter-subaccordion-header-font-weight: @font-bold-weight;
	 @ems-analysis-filter-subaccordion-header-font-size: 16px;
	 @ems-analysis-filter-subaccordion-header-color: @color-2;
	 @ems-analysis-filter-subaccordion-body-background-color: @grey-6;


	// ems main card (includes diagram + details table and last import line)
	 @ems-analysis-main-card-order: 2;
	 @ems-analysis-diagram-background-color: @white;
	 @ems-analysis-diagram-details-table-container-tabs-height: 41px;
	 @ems-analysis-diagram-details-table-container-background-color: @white;
	 @ems-analysis-diagram-details-table-container-resize-buttons-color: @color-2;
	 @ems-analysis-diagram-details-table-container-tab-content-background-color: @white;

	 @ems-analysis-main-card-last-import-height: 24px;
	 @ems-analysis-main-card-last-import-background-color: @grey-7;
	 @ems-analysis-main-card-last-import-font-family: @font-regular;
	 @ems-analysis-main-card-last-import-font-weight: @font-regular-weight;
	 @ems-analysis-main-card-last-import-font-size: 14px;
	 @ems-analysis-main-card-last-import-color: @font-color;

	// ems diagram loading progress bar
	 @ems-progress-bar-text-font-family: @font-regular;
	 @ems-progress-bar-text-font-weight: @font-regular-weight;
	 @ems-progress-bar-text-font-size: 16px;
	 @ems-progress-bar-text-color: @font-color;	
	 @ems-diagram-loading-progress-bar-border-radius: 0.25rem;
	 @ems-diagram-loading-progress-bar-background-color: @color-3;
	 
	// ems object jstree modal dialogue
	 @ems-object-jstree-modal-dialogue-max-width: 50%;
	 
	// ems jstree
	 @ems-jstree-icon-color: @grey-4;
	 @ems-jstree-icon-color-hover: @grey-2;
	 @ems-jstree-icon-color-clicked: @color-2;
	 @ems-jstree-anchor-color: @color-2;

	// ems analysis diagram colors
	 @color-ANALYSIS_PLOT-1: #7cb5ec;
	 @color-ANALYSIS_PLOT-2: #434348;
	 @color-ANALYSIS_PLOT-3: #90ed7d;
	 @color-ANALYSIS_PLOT-4: #f7a35c;
	 @color-ANALYSIS_PLOT-5: #8085e9;
	 @color-ANALYSIS_PLOT-6: #f15c80;
	 @color-ANALYSIS_PLOT-7: #e4d354;
	 @color-ANALYSIS_PLOT-8: #8d4653;
	 @color-ANALYSIS_PLOT-9: #91e8e1;	

