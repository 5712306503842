
.mixin(ems-integration) {

	body.ems-integration {
		
		background: none @ems-integration-body-background-start-color; /* old browsers */
		background: -moz-linear-gradient(top, @ems-integration-body-background-start-color 0%, @ems-integration-body-background-end-color 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, @ems-integration-body-background-start-color 0%, @ems-integration-body-background-end-color 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(top, @ems-integration-body-background-start-color 0%, @ems-integration-body-background-end-color 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@ems-integration-body-background-start-color', endColorstr='@ems-integration-body-background-end-color', GradientType=1); /* Gradient IE6-9 */ 
		background-size: auto;
		background-attachment: fixed;
				
		header {
			height: calc(~'@{header-height} + @{header-context-height}');
		}
		
		main {
			height: calc(~'100% - @{footer-height}');
			padding-top: calc(~'@{main-padding-top} + @{header-context-height}');
			
			.main-process-top-stripe {
				background-color: @ems-analysis-card-header-background-color;
			}
			
			#main-process-container {
				height: 100%;
			
				#main-process-cage {
					height: 100%;
					
					.main-process {
						width: 100%;

						
						height: 100%;						
						overflow: hidden;	
					}
				}	
			}
		}

		@media (max-width: @viewport-sm-max) {
						
			header {
				height: calc(~'@{header-height-sm} + @{header-context-height}');
			}
			
			main {
				height: auto;
				padding-top: calc(~'@{main-padding-top-sm} + @{header-context-height}');
				
				#main-process-container {
					height: auto;
					
					#main-process-cage {
						height: auto;
					}
				}	
			}		
		}	
		
	}
}
@ems-integration: ems-integration;

