
.mixin(ems-jstree) {

	.jstree-default .jstree-node .jstree-clicked {
		background: transparent none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		font-family: @font-bold;
		font-weight: @font-bold-weight;
	}

	.jstree-default .jstree-node .jstree-hovered {
		background: transparent none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}

	.jstree-default .jstree-node {
		.jstree-anchor .jstree-icon {
			color: @ems-jstree-icon-color;
		}
		
		.jstree-anchor.jstree-clicked {
			.jstree-icon {
				color: @ems-jstree-icon-color-clicked;
			}	
		}
		.jstree-anchor.jstree-hovered {
			.jstree-icon {
				color: @ems-jstree-icon-color-hover;
			}	
		}	
	}

	.jstree-default .jstree-themeicon {
		margin-top: -2px;
	}

	.jstree-default .jstree-checkbox {
		background-image: url('../../../images/de_DE/sprites-sfa68604977.png');
		background-position: -3px -4px;
		
		&:hover {
			background-position: -33px -4px;
		}
	}

	.jstree-default .jstree-anchor > .jstree-undetermined {
		background-position: -153px -4px;
	}

	.jstree-default .jstree-clicked > .jstree-checkbox {
		background-position: -63px -4px;
		
		&:hover {
			background-position: -93px -4px;
		}
	}
	
	a.jstree-disabled > i.jstree-checkbox {
		display: none;
	}
	
	.jstree-anchor,
	.jstree-anchor:link,
	.jstree-anchor:visited,
	.jstree-anchor:hover,
	.jstree-anchor:active {
		color: @ems-jstree-anchor-color;
	}

	a.jstree-anchor .bold {
		font-family: @font-bold;
		font-weight: @font-bold-weight;
	}
}
@ems-jstree: ems-jstree;
