
.mixin(ems-datetimepicker) {

	.bootstrap-datetimepicker-widget {
		z-index: 10;
		background-color: transparent;
		
		ul {
			margin-bottom: 0.5rem;
			li {
				width: auto;
				min-width: 16em;
			}	
		}
		
		.datepicker {
			th.prev, th.next {
				font-size: 14px;
				
				i.fas {
					margin-top: 0.5rem;
				}
			}
			th.picker-switch {
				background-color: @calendar-th-switch-background-color;
				color: @calendar-th-switch-color;
				border-radius: 0px;
				height: 40px;
				&:hover, &:focus {
					background-color: @calendar-th-switch-background-color-hover !important;
					color: @calendar-th-switch-color-hover;        
				}
			}
			
			tbody {
				td {
					padding: 0px;
					
					span {
						margin: 0px;
						line-height: 48px;
						padding: 5px 8px;
						margin-top: 2px;
						width: auto;
						height: auto;
						min-width: 47px;
						min-height: 54px;
					}
				}
				
				td.cw,
				td span.quarter {
					border-radius: 0px;
				}
				
				td.day.disabled,
				td span.quarter.disabled,
				td span.month.disabled,
				td span.year.disabled {
					background-color: @white;
					color: @grey-6;
					&:hover, &:focus {
						background-color: @white;
						color: @grey-6;
						cursor: default;
					}
				}
			}
		}
	}
	
	.bootstrap-datetimepicker-widget.doublepicker {
	
		background-color: @white;
		-webkit-box-shadow: @calendar-box-shadow;
		-moz-box-shadow: @calendar-box-shadow;
		box-shadow: @calendar-box-shadow;
		padding: 0.5rem;
		
		.datepicker {
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
		}
	}
}
@ems-datetimepicker: ems-datetimepicker;