
/* ******************************** */
/*       Default Customizing        */
/* ******************************** */

.mixin(@body-bg-cockpit,@cockpit-bg-type);
.mixin(@body-bg-process,@process-bg-type);
.mixin(@base-layout);
.mixin(@header);
.mixin(@header-menu);
.mixin(@alerts);
.mixin(@popovers);
.mixin(@main-login-card);
.mixin(@cockpit-cards);
.mixin(@context-menu);
.mixin(@process-cards);
.mixin(@form-rows);
.mixin(@form-labels);
.mixin(@form-inputs);
.mixin(@input-groups);
.mixin(@calendar);
.mixin(@file-input);
.mixin(@form-floating);
.mixin(@buttons);
.mixin(@links);
.mixin(@slider);
.mixin(@selects);
.mixin(@autocomplete);
.mixin(@radios);
.mixin(@checkboxes);
.mixin(@meter-value-display);
.mixin(@tabs);
.mixin(@modal-dialogue);
.mixin(@tables);
.mixin(@datatables);
.mixin(@nomoretable);
.mixin(@multi-meter-value-input-table);
.mixin(@tariff-calculator);
.mixin(@tariff-cards);
.mixin(@base-supplier-card);
.mixin(@accordion);
.mixin(@marginal-columns);
.mixin(@footer);
.mixin(@service-status);
.mixin(@cash-payments);
.mixin(@service-status);

/* integrate EMS - Analyse */
.mixin(@ems-integration);
.mixin(@ems-analysis);
.mixin(@ems-jstree);
.mixin(@ems-datetimepicker);


/* ++++++++++++++++++++++ */
/* Additional Customizing */
/* ++++++++++++++++++++++ */

.input-group > .form-floating {
	~ .input-group-text.info-default,
	~ .input-group-text.alert-danger,
	~ .input-group-text.alert-warning,
	~ .input-group-text.alert-info {
		border-left: 0px !important;
	}
}

body.cockpit .form-control:read-only, .form-control:read-only:focus {
  border-color: @grey-5;
}

.fa.fa-info-circle.alert-info,
.fa.fa-info-circle.alert-info:focus {
  background-color: transparent;
}

#cockpit-cards-container {
	.card {
		form {
			display: contents;

			.card-footer {
				padding-top: 0px;
				padding-bottom: 1rem;
				background-color: @cockpit-card-background-color;
				border-top: 0px;

				.card-button-line {
					padding-top: 0.5rem;
				}
			}
		}
    }
	@media (max-width: @viewport-sm-max) {
		.card {
			border: 0px;
			
			.card-body {
				padding-bottom: 0px;
			}
			
			.card-footer {
                display: none;
				border-radius: 0px;
            }

			&:nth-child(2n-1) {	
				.card-footer {
					background-color: @cockpit-card-body-background-color-sm-uneven;
				}	
			}
			&:nth-child(2n) {
				.card-footer {
					background-color: @cockpit-card-body-background-color-sm-even;
				}
			}	
		}
		.card.opened-sm {
			.card-footer {
				display: block;
			}
		}
	}	
}

dt
{
	font-weight: normal;
	margin-bottom: 0.5rem;
}

dd
{
	margin-left: 40px;
}


.fa-envelope {
	font-size: 24px;
	display: block;
	margin-top: 6px;
}

.mixin(header-menu) {
	#header-menu {
		.fa-comments {
			font-size: 24px;
			margin-top: 6px;
		}
	}
}

.mixin(tariff-cards) {

	.main-process .card .tariffs-display-container {

		.tariff-cards-display {

			.tariff-card {

				.card-header {

					.card-subtitle {
						font-family: @tariff-card-subtitle-font-family;
						font-weight: @tariff-card-subtitle-font-weight;
						font-size: @tariff-card-subtitle-font-size;
						color: @tariff-card-subtitle-color;
					}
				}

				.card-footer {

					.tariff-additional-details-link {
						padding: 0.5rem 1rem;
						text-align: center;
						background-color: @tariff-card-tariff-additional-details-link-background-color;

						.form-link {
							.fa-chevron-circle-down {
								display: inline-block;
							}
							.fa-chevron-circle-up {
								display: none;
							}
						}
					}
					.tariff-additional-details-row {
						padding: 0.5rem 1rem;
						background-color: @tariff-card-tariff-additional-details-row-background-color;
						font-size: @tariff-card-tariff-additional-details-row-font-size;
						color: @tariff-card-tariff-additional-details-row-color;

						.d-table {
							width: 100%;
						}
					}
				}
			}

			.tariff-card.show-tariff-additional-details {
				.card-footer {
					.tariff-additional-details-link {
						.form-link {
							.fa-chevron-circle-down {
								display: none;
							}
							.fa-chevron-circle-up {
								display: inline-block;
							}
						}
					}
				}
			}

			.tariff-card.recommended-tariff {

				.card-subtitle {
					font-family: @recommended-tariff-card-subtitle-font-family;
					font-weight: @recommended-tariff-card-subtitle-font-weight;
					font-size: @recommended-tariff-card-subtitle-font-size;
					color: @recommended-tariff-card-subtitle-color;
				}

				.card-footer {

					.tariff-additional-details-link {
						background-color: @recommended-tariff-card-tariff-additional-details-link-background-color;
					}
					.tariff-additional-details-row {
						background-color: @recommended-tariff-card-tariff-additional-details-row-background-color;
						font-size: @recommended-tariff-card-tariff-additional-details-row-font-size;
						color: @recommended-tariff-card-tariff-additional-details-row-color;
					}
				}
			}
		}
	}
}