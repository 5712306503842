
@font-face {
	font-family: 'icomoon';
			src: url('../../../fonts/icomoon.eot?-g0vw1s');
			src: url('../../../fonts/icomoon.eot?#iefix-g0vw1s') format('embedded-opentype'),
				 url('../../../fonts/icomoon.woff?-g0vw1s') format('woff'),
				 url('../../../fonts/icomoon.ttf?-g0vw1s') format('truetype'),
				 url('../../../fonts/icomoon.svg?-g0vw1s#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

.using-icomoon {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	color: @font-color;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icomoon-power {
	margin-top: 0px !important;
	&:before {
		content: "\e601";
	}
}
.icomoon-power-virtual {
	margin-top: 0px !important;
	&:before {
		content: "\e911";
	}
}
.icomoon-gas {
	margin-top: 0px !important;
	&:before {
		content: "\e602";
	}
}
.icomoon-gas-virtual {
	margin-top: 0px !important;
	&:before {
		content: "\e910";
	}
}
.icomoon-water {
	margin-top: 0px !important;
	&:before {
		content: "\e603";
	}
}
.icomoon-water-virtual {
	margin-top: 0px !important;
	&:before {
		content: "\e90e";
	}
}
.icomoon-thermal {
	margin-top: 0px !important;
	&:before {
		content: "\e604";
	}
}
.icomoon-thermal-virtual {
	margin-top: 0px !important;
	&:before {
		content: "\e90f";
	}
}
.icomoon-cold {
	margin-top: 0px !important;
	&:before {
		content: "\e913";
	}
}
.icomoon-cold-virtual {
	margin-top: 0px !important;
	&:before {
		content: "\e900";
	}
}
.icomoon-parameter {
	margin-top: 0px !important;
	&:before {
		content: "\e904";
	}
}
.icomoon-fleet {
	margin-top: 0 !important;
	&:before {
		content: "\e907";
	}
}
.icomoon-fleet-virtual {
	margin-top: 0px !important;
	&:before {
		content: "\e90d";
	}
}
.fa-thermometer-half {
	margin-top: 0 !important;
}
.icomoon-not_set {
	margin-top: 0px !important;
	&:before {
		content: "\e903";
	}
}	