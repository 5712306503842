
.mixin(ems-analysis) {

	.main-process {
		.ems-analysis-card {
			width: 100%;
			height: 100%;
			
			.card-header {
				height: @ems-analysis-card-header-height;
				background-color: @ems-analysis-card-header-background-color;
				border-bottom: @ems-analysis-card-header-border-bottom;
				padding: 0 1rem;
				
				.card-title {
					font-family: @ems-analysis-card-header-font-family;
					font-weight: @ems-analysis-card-header-font-weight;
					font-size: @ems-analysis-card-header-font-size;
					color: @ems-analysis-card-header-color;
					padding-top: 3px;				
				}
				
				.ems-analysis-card-header-toolbar {
					margin-right: 26px;
					
					.analysis-card-header-tool {
						width: @ems-analysis-card-header-height;
						height: @ems-analysis-card-header-height;
						border-right: 1px solid @ems-analysis-card-header-background-color;
						
						a, span {
							display: inline-block;
							text-decoration: none;
							text-align: center;
							width: 100%;
							height: 100%;
							cursor: pointer;
							padding-top: 9px;
							
							.fa, .fas {
								font-size: 18px;
								color: @ems-analysis-card-header-toolbar-icon-color; 
							}
							
							&:hover, &:focus {
								text-decoration: none;
								
								.fa, .fas {
									color: @ems-analysis-card-header-toolbar-icon-color-hover-focus; 
								}
							}							
						}
						span.dropdown-toggle {
							&:after {
								display: none !important;
							}
							
							.fa-spinner {
								display: none;
							}
						}
						a.iconbtn, span.iconbtn {
							background-color: @ems-analysis-card-header-toolbar-iconbtn-background-color;
							
							.fa, .fas {
								color: @ems-analysis-card-header-toolbar-iconbtn-icon-color;
							}

							&:hover, &:focus {
								background-color: @ems-analysis-card-header-toolbar-iconbtn-background-color-hover-focus;
							
								.fa, .fas {
									color: @ems-analysis-card-header-toolbar-iconbtn-icon-color-hover-focus;
								}
							}	
						}
						.min-max-analysis {
							.fa-expand-alt {
								display: inline-block;
							}
							.fa-compress-alt {
								display: none;
							}
						}	
					}
					.analysis-card-header-tool.dropdown {
						ul.dropdown-menu.show {
							inset: @ems-analysis-card-header-height 0 auto auto !important;
							transform: none !important;
							padding: 0px;
							border-radius: 0px;
							border: @header-menu-dropdown-menu-border;
							border-top: 0px;
							-webkit-box-shadow: @header-menu-dropdown-menu-box-shadow;
							-moz-box-shadow: @header-menu-dropdown-menu-box-shadow;
							box-shadow: @header-menu-dropdown-menu-box-shadow;
							
							> li {
								
								> a.dropdown-item,
								> span.dropdown-item {
									background-color: @header-menu-dropdown-item-background-color;
									border-bottom: @header-menu-dropdown-item-border-bottom;
									color: @header-menu-dropdown-item-color;
									font-family: @header-menu-dropdown-item-font-family;
									font-weight: @header-menu-dropdown-item-font-weight;
									font-size: @header-menu-dropdown-item-font-size;
									padding: @header-menu-dropdown-item-padding;
									text-align: left;
									
									.fas {
										color: @header-menu-dropdown-item-color;
										font-size: @header-menu-dropdown-item-font-size;
									}
									.fa-file-pdf {
										margin-right: 16px;
									}
									.fa-download {
										margin-right: 12px;
									}
									.fa-clipboard {
										margin-right: 16px;
									}
									.fa-bookmark {
										margin-right: 16px;
									}
									.fa-paint-brush {
										margin-right: 12px;
									}
									
									&:hover, &:focus {
										background-color: @header-menu-dropdown-item-background-color-hover;
										border-bottom: @header-menu-dropdown-item-border-bottom-hover;
										color: @header-menu-dropdown-item-color-hover;
										font-family: @header-menu-dropdown-item-font-family-hover;
										font-weight: @header-menu-dropdown-item-font-weight-hover;
										font-size: @header-menu-dropdown-item-font-size-hover;
										border-left: @header-menu-dropdown-item-border-left-hover;
										padding: @header-menu-dropdown-item-padding-hover;
										
										.fas {
											color: @header-menu-dropdown-item-color-hover;
										}	
									}									
								}

								.accordion {
									.accordion-item {
										h3.accordion-header {
											.accordion-button,
											.accordion-button.collapsed	{
												background-color: @header-menu-dropdown-item-background-color;
												border-bottom: @header-menu-dropdown-item-border-bottom;
												color: @header-menu-dropdown-item-color;
												font-family: @header-menu-dropdown-item-font-family;
												font-weight: @header-menu-dropdown-item-font-weight;
												font-size: @header-menu-dropdown-item-font-size;
												padding: @header-menu-dropdown-item-padding;
												padding-top: 4.5px;
												padding-bottom: 4.5px;	
												
												.fas {
													color: @header-menu-dropdown-item-color;
													font-size: @header-menu-dropdown-item-font-size;
												}
												.fa-download {
													margin-right: 12px;
												}
												.fa-spinner {
													margin-right: 12px;
													display: none;
												}
												.fa-caret-up,
												.fa-caret-down {
													font-size: 20px;
												}
												
												&:hover {
													background-color: @header-menu-dropdown-item-background-color-hover;
													border-bottom: @header-menu-dropdown-item-border-bottom-hover;
													color: @header-menu-dropdown-item-color-hover;
													font-family: @header-menu-dropdown-item-font-family-hover;
													font-weight: @header-menu-dropdown-item-font-weight-hover;
													font-size: @header-menu-dropdown-item-font-size-hover;
													border-left: @header-menu-dropdown-item-border-left-hover;
													padding: @header-menu-dropdown-item-padding-hover;													
													padding-top: 4.5px;
													padding-bottom: 4.5px;
												
													.fas {
														color: @header-menu-dropdown-item-color-hover;
													}	
												}
											}
											.accordion-button:not(.collapsed) {
												-webkit-box-shadow: none;
												-moz-box-shadow: none;
												box-shadow: none;
												background-color: @header-menu-dropdown-item-background-color-hover;
												border-bottom: @header-menu-dropdown-item-border-bottom-hover;
												color: @header-menu-dropdown-item-color-hover;
												font-family: @header-menu-dropdown-item-font-family-hover;
												font-weight: @header-menu-dropdown-item-font-weight-hover;
												font-size: @header-menu-dropdown-item-font-size-hover;
												border-left: @header-menu-dropdown-item-border-left-hover;
												padding: @header-menu-dropdown-item-padding-hover;
												padding-top: 4.5px;
												padding-bottom: 4.5px;	
												
												.fas {
													color: @header-menu-dropdown-item-color-hover;
												}	
											}
										}
										.accordion-body {
											padding: 0px 0px 8px 0px;
											background-color: @grey-8;
											
											a {
												text-align: left;
												color: @header-menu-dropdown-item-color;
												font-family: @header-menu-dropdown-item-font-family;
												font-weight: @header-menu-dropdown-item-font-weight;
												font-size: @header-menu-dropdown-item-font-size;
												padding: 8px 16px 0px 44px;
												white-space: nowrap;
												
												&:hover, &:focus {
													color: @header-menu-dropdown-item-color-hover;
													font-family: @header-menu-dropdown-item-font-family-hover;
													font-weight: @header-menu-dropdown-item-font-weight-hover;
													font-size: @header-menu-dropdown-item-font-size-hover;
												}
											}
										}	
									}
								}		
								
								&:last-child {
									> a.dropdown-item,
									> span.dropdown-item {
										border-bottom: 0px;
									}	
								}
							}
							
							@media (max-width: calc(~'(@{viewport-sm-min} / 3) * 2')) {
								margin-right: calc(~'-1 * @{ems-analysis-card-header-height}') !important;  
							}
						}	
					}
					.analysis-card-header-tool.dropdown.download-in-progress {
						span.dropdown-toggle {
							cursor: default;

							.fa-spinner {
								display: inline-block;
							}
							.fa-download {
								display: none !important;
							}	
						}
						ul.dropdown-menu.show {
							display: none;
						}
					}
				}
			}
			.ems-analysis-card-body {
				padding: 0px;
				height: calc(~'100% - @{ems-analysis-card-header-height}');
				
				.analysis-filter-container {
					width: @ems-analysis-filter-container-initial-width;
					background-color: @ems-analysis-filter-container-background-color;
					height: 100%;
					order: @ems-analysis-filter-container-order;
					max-width: @ems-analysis-filter-container-resizable-max-width;
					min-width: @ems-analysis-filter-container-resizable-min-width;
					flex: 0 0 auto;	
					
					.analysis-filter-content-container {
						max-height: 100%;
						width: 100%;
						overflow: auto;
						padding: 1rem;
						order: @ems-analysis-filter-content-container-order;
					
						.analysis-filter-buttons-container {
							margin-left: -1px;
							margin-right: -1px;
							
							.btn {
								margin: 0px 1px 2px 1px;
							}
							.btn-primary {						
								background-color: @ems-analysis-filter-button-primary-background-color;
								border: @ems-analysis-filter-button-primary-border;
								border-radius: @ems-analysis-filter-button-primary-border-radius;
								font-family: @ems-analysis-filter-button-primary-font-family;
								font-weight: @ems-analysis-filter-button-primary-font-weight;
								font-size: @ems-analysis-filter-button-primary-font-size;
								color: @ems-analysis-filter-button-primary-color;
								padding: @ems-analysis-filter-button-primary-padding;
								
								&:hover, &:focus {
									background-color: @ems-analysis-filter-button-primary-background-color-hover-focus;
									border: @ems-analysis-filter-button-primary-border-hover-focus;
									color: @ems-analysis-filter-button-primary-color-hover-focus;
								}
							}
							.btn-primary.btn-active {						
								background-color: @ems-analysis-filter-button-primary-active-background-color;
								border: @ems-analysis-filter-button-primary-active-border;
								font-family: @ems-analysis-filter-button-primary-active-font-family;
								font-weight: @ems-analysis-filter-button-primary-active-font-weight;
								font-size: @ems-analysis-filter-button-primary-active-font-size;
								color: @ems-analysis-filter-button-primary-active-color;
								&:hover, &:focus {
									background-color: @ems-analysis-filter-button-primary-active-background-color-hover-focus;
									border: @ems-analysis-filter-button-primary-active-border-hover-focus;
									color: @ems-analysis-filter-button-primary-active-color-hover-focus;
								}
							}
							.btn-default {
								background-color: @ems-analysis-filter-button-default-background-color;
								border: @ems-analysis-filter-button-default-border;
								border-radius: @ems-analysis-filter-button-default-border-radius;
								font-family: @ems-analysis-filter-button-default-font-family;
								font-weight: @ems-analysis-filter-button-default-font-weight;
								font-size: @ems-analysis-filter-button-default-font-size;
								color: @ems-analysis-filter-button-default-color;
								padding: @ems-analysis-filter-button-default-padding;
								
								&:hover, &:focus {
									background-color: @ems-analysis-filter-button-default-background-color-hover-focus;
									border: @ems-analysis-filter-button-default-border-hover-focus;
									color: @ems-analysis-filter-button-default-color-hover-focus;
								}
							}
							.btn-default.btn-active {
								background-color: @ems-analysis-filter-button-default-active-background-color;
								border: @ems-analysis-filter-button-default-active-border;
								font-family: @ems-analysis-filter-button-default-active-font-family;
								font-weight: @ems-analysis-filter-button-default-active-font-weight;
								font-size: @ems-analysis-filter-button-default-active-font-size;
								color: @ems-analysis-filter-button-default-active-color;
								
								&:hover, &:focus {
									background-color: @ems-analysis-filter-button-default-active-background-color-hover-focus;
									border: @ems-analysis-filter-button-default-active-border-hover-focus;
									color: @ems-analysis-filter-button-default-active-color-hover-focus;
								}
							}
						}
						
						.indicator-object {
							background-color: @ems-analysis-filter-indicator-object-background-color;
							border-left: @ems-analysis-filter-indicator-object-border-left;
							padding: 0.5rem 0.75rem 0.5rem 0.5rem;
							margin-top: 16px;
							
							.indicator-object-text {
								font-family: @ems-analysis-filter-indicator-object-text-font-family;
								font-weight: @ems-analysis-filter-indicator-object-text-font-weight;
								font-size: @ems-analysis-filter-indicator-object-text-font-size;
								color: @ems-analysis-filter-indicator-object-text-color;
								padding-right: 0.5rem;
							}
							.fa-exchange-alt {
								color: @ems-analysis-filter-indicator-object-exchange-icon-color;
								cursor: pointer;
							}
						}
						
						.comparison-object {
							background-color: @ems-analysis-filter-comparison-object-background-color;
							border-left: @ems-analysis-filter-comparison-object-border-left;
							padding: 0.5rem 0.75rem 0.5rem 0.5rem;
							
							.comparison-object-text {
								font-family: @ems-analysis-filter-comparison-object-text-font-family;
								font-weight: @ems-analysis-filter-comparison-object-text-font-weight;
								font-size: @ems-analysis-filter-comparison-object-text-font-size;
								color: @ems-analysis-filter-comparison-object-text-color;
								padding-right: 0.5rem;
							}
							.fa-window-close {
								color: @ems-analysis-filter-comparison-object-close-icon-color;
								cursor: pointer;
							}
						}
						
						.interval-row {
							background-color: @ems-analysis-filter-interval-row-background-color;
							padding: 0.5rem 0.75rem;
							
							.interval {
								font-family: @ems-analysis-filter-interval-font-family;
								font-weight: @ems-analysis-filter-interval-font-weight;
								font-size: @ems-analysis-filter-interval-font-size;
								color: @ems-analysis-filter-interval-color;
								cursor: pointer;
							}
							
							.fa-calendar-alt {
								color: @ems-analysis-filter-interval-row-calendar-icon-color;
								cursor: pointer;	
							}
							.fa-lock,
							.fa-lock-open {
								color: @ems-analysis-filter-interval-row-lock-icon-color;
								cursor: pointer;
							}
							.fa-caret-square-right,
							.fa-caret-square-left {
								color: @ems-analysis-filter-interval-row-caret-icon-color;
								cursor: pointer;
							}
							.fa-caret-square-right.theoreticalInterval,
							.fa-caret-square-left.theoreticalInterval {
								color: @ems-analysis-filter-theoretical-interval-row-caret-icon-color;
								background-color: @ems-analysis-filter-theoretical-interval-row-caret-icon-background-color;
								border: @ems-analysis-filter-theoretical-interval-row-caret-icon-border;
								border-radius: @ems-analysis-filter-theoretical-interval-row-caret-icon-border-radius;
								border-top: 0px;
								border-bottom: 0px;
							}
							.fa-caret-square-right.inactive,
							.fa-caret-square-left.inactive {
								color: @ems-analysis-filter-interval-row-caret-icon-inactive-color;
								cursor: default;
							}
							.fa-window-close {
								color: @ems-analysis-filter-interval-row-close-icon-color;
								cursor: pointer;
							}
						}
						.added-interval-row {
							border-top: @ems-analysis-filter-add-interval-row-border-top;
						}
						.add-interval-row {
							background-color: @ems-analysis-filter-add-interval-row-background-color;
							padding: 0.5rem 0.75rem;
							border-top: @ems-analysis-filter-add-interval-row-border-top;
							font-family: @ems-analysis-filter-add-interval-row-font-family;
							font-weight: @ems-analysis-filter-add-interval-row-font-weight;
							font-size: @ems-analysis-filter-add-interval-row-font-size;
							color: @ems-analysis-filter-add-interval-row-color;
							cursor: pointer;
						}
						.add-object-row {
							background-color: @ems-analysis-filter-add-object-row-background-color;
							padding: 0.5rem 0.75rem;
							border-top: @ems-analysis-filter-add-object-row-border-top;
							font-family: @ems-analysis-filter-add-object-row-font-family;
							font-weight: @ems-analysis-filter-add-object-row-font-weight;
							font-size: @ems-analysis-filter-add-object-row-font-size;
							color: @ems-analysis-filter-add-object-row-color;
							cursor: pointer;
						}
						
						.accordion {
							margin-top: 16px;
							
							.accordion-item {
								h3.accordion-header .accordion-button {
									border-bottom: 0px;
									border-radius: 0px;
									background-color: @ems-analysis-filter-accordion-header-background-color;
									font-family: @ems-analysis-filter-accordion-header-font-family;
									font-weight: @ems-analysis-filter-accordion-header-font-weight;
									font-size: @ems-analysis-filter-accordion-header-font-size;
									color: @ems-analysis-filter-accordion-header-color;
									border-left: @ems-analysis-filter-accordion-header-border-left;
									padding: 0.5rem 1rem 0.5rem 0.5rem;
									-webkit-box-shadow: none;
									-moz-box-shadow: none;
									box-shadow: none;
									
									&:hover, &:focus {
										border-bottom: 0px;
									}
									
									.fa-caret-down,
									.fa-caret-up {
										font-size: 20px;
										vertical-align: bottom;
									}
								}
								.accordion-body {
									padding: 0px;
									background-color: @ems-analysis-filter-accordion-body-background-color;
									
									.accordion-body-checkbox-row,
									.accordion-body-radio-row {
										padding: 0.15rem 1rem 0.5rem 1.25rem;
									}
									
									.subaccordion {
										margin-top: 0px;
										
										.accordion-item {
											h3.accordion-header .accordion-button {
												border-left: 0px;
												background-color: @ems-analysis-filter-subaccordion-header-background-color;
												font-family: @ems-analysis-filter-subaccordion-header-font-family;
												font-weight: @ems-analysis-filter-subaccordion-header-font-weight;
												font-size: @ems-analysis-filter-subaccordion-header-font-size;
												color: @ems-analysis-filter-subaccordion-header-color;
												padding: 0.15rem 1rem 0.75rem 1.25rem;
												
												.subaccordion-header-checkbox-row,
												.subaccordion-header-radio-row {
													
												}
												
												.fa-caret-down,
												.fa-caret-up {
													margin-top: 6px;
												}
												
												input[type=checkbox]:not(old) + label,
												input[type=radio]:not(old) + label	{
													font-family: @ems-analysis-filter-subaccordion-header-font-family;
													font-weight: @ems-analysis-filter-subaccordion-header-font-weight;
													font-size: @ems-analysis-filter-subaccordion-header-font-size;
													color: @ems-analysis-filter-subaccordion-header-color;
												}
											}
											.accordion-body {
												background-color: @ems-analysis-filter-subaccordion-body-background-color;
												padding: 0.25rem 1rem 0.5rem 3.25rem;	
											
												.subaccordion {
												
													.accordion-item {
														h3.accordion-header .accordion-button {
															background-color: @ems-analysis-filter-subaccordion-body-background-color;
															padding: 0.15rem 0rem 0.75rem 0rem;
															color: @font-color;	

															.subaccordion-header-checkbox-row,
															.subaccordion-header-radio-row {
																
																input[type=checkbox]:not(old) + label,
																input[type=radio]:not(old) + label	{
																	color: @font-color;
																}
															}
														}
														.accordion-body {
															padding: 0rem 0rem 0.5rem 2rem;
															
															.subaccordion-body-checkbox-row,
															.subaccordion-body-radio-row {
																margin-bottom: 0.5rem;																
															}
														}
													}			
												}
											
											}
										}	
									}	
								}
							}						
						}
					}
					.analysis-filter-toggle-container {
						background-color: @ems-analysis-filter-toggle-container-background-color;
						width: @ems-analysis-filter-toggle-container-width;
						order: @ems-analysis-filter-toggle-container-order;
						padding-top: 1rem;
						cursor: col-resize;
						
						.open-close-filter {
							width: 100%;
							height: @ems-analysis-filter-open-close-filter-height;
							background-color: @ems-analysis-filter-open-close-filter-background-color;
							cursor: pointer;
							text-align: center;
							
							.fa-filter {
								color: @ems-analysis-filter-open-close-filter-icon-color;
								font-size: 22px;
								margin-top: 8px;
							}
							.fa-arrow-alt-circle-left {
								display: inline-block;
								color: @ems-analysis-filter-open-close-filter-icon-color;
								font-size: 22px;
								margin-top: 10px;
							}
							.fa-arrow-alt-circle-right {
								display: none;
								color: @ems-analysis-filter-open-close-filter-icon-color;
								font-size: 22px;
								margin-top: 10px;
							}							
							
							&:hover {
								background-color: @ems-analysis-filter-open-close-filter-background-color-hover;
								
								.fa-filter,
								.fa-arrow-alt-circle-left,
								.fa-arrow-alt-circle-right {
									color: @ems-analysis-filter-open-close-filter-icon-color-hover;
								}
							}
						}	
					}	
				}
				
				.analysis-main-card {
					width: 100%;
					flex: 1 1 auto;
					order: @ems-analysis-main-card-order;
					
					.analysis-main-card-body {
						padding: 0px;
						height: calc(~'100% - @{ems-analysis-main-card-last-import-height}');
						
						.analysis-main-card-diagram-and-details {
							height: 100%;
							overflow: hidden;
							
							.analysis-diagram-card-body {
								height: calc(~'100% - @{ems-analysis-diagram-details-table-container-tabs-height}');
								background-color: @ems-analysis-diagram-background-color;
								
								.analysis-diagram-container {
									height: 100%;
									width: 100%;
									
									/*** hier dann wahrscheinlich div.highcharts-container ***/
									svg { /*** svg nur zur Simulation ***/
										min-width: 100%;
										min-height: 100%;
										flex-shrink: 1;
									}
								}
							}
							.analysis-diagram-details-table-container {
								height: @ems-analysis-diagram-details-table-container-tabs-height;
								background-color: @ems-analysis-diagram-details-table-container-background-color;
								padding-left: 2px;
								padding-right: 2px;
								
								.diagram-details-resize-buttons {
									float: right;
									margin-right: 1rem;
									margin-top: 0.5rem;
									display: none;
									
									.fa, .fas {
										font-size: 22px;
										color: @ems-analysis-diagram-details-table-container-resize-buttons-color;
										margin-left: 0.25rem;
										cursor: pointer;
									}
								}
								
								.tab-content {
									padding: 2px;
									height: calc(~'100% - @{ems-analysis-diagram-details-table-container-tabs-height}');
									overflow: auto;
									background-color: @ems-analysis-diagram-details-table-container-tab-content-background-color;
									
									.tab-pane {
										.form-row {
											margin-left: 0px;
											margin-right: 0px;
											margin-bottom: 0px;
										}
									}
								}
							}	
						}
						.analysis-main-card-diagram-and-details.details-table-quarter-height {
							.analysis-diagram-card-body {
								height: 75%;
							}
							.analysis-diagram-details-table-container {
								height: 25%;
								margin-bottom: 2px;	
								
								.diagram-details-resize-buttons {
									display: block;
								}
							}
						}
						.analysis-main-card-diagram-and-details.details-table-half-height {
							.analysis-diagram-card-body {
								height: 50%;
							}
							.analysis-diagram-details-table-container {
								height: 50%;
								margin-bottom: 2px;
								
								.diagram-details-resize-buttons {
									display: block;
								}
							}
						}
						.analysis-main-card-diagram-and-details.details-table-full-height {
							.analysis-diagram-card-body {
								height: 0px;
								padding: 0px;
							}
							.analysis-diagram-details-table-container {
								height: 100%;
								padding-top: 1rem;
								margin-bottom: 2px;
								
								.diagram-details-resize-buttons {
									display: block;
									
									.fa-arrow-alt-circle-up {
										display: none;
									}
								}
							}
						}
					}
					.analysis-main-card-last-import {
						height: @ems-analysis-main-card-last-import-height;
						background-color: @ems-analysis-main-card-last-import-background-color;
						font-family: @ems-analysis-main-card-last-import-font-family;
						font-weight: @ems-analysis-main-card-last-import-font-weight;
						font-size: @ems-analysis-main-card-last-import-font-size;
						color: @ems-analysis-main-card-last-import-color;
						text-align: right;
						line-height: 22px;
					}				
				}

				.diagram-loading {
					width: 100%;
					height: 100%;

					.progress-loading-container {

						width: 50%;
						margin: 2rem 3rem 3rem 3rem;

						.progress-bar-text {
							text-align: center;
							font-family: @ems-progress-bar-text-font-family;
							font-weight: @ems-progress-bar-text-font-weight;
							font-size: @ems-progress-bar-text-font-size;
							color: @ems-progress-bar-text-color;
							margin-bottom: 0.5rem;
						}	

						.progress {
							
							border-radius: @ems-diagram-loading-progress-bar-border-radius;

							.progress-bar {
								width: 100%;
								background-color: @ems-diagram-loading-progress-bar-background-color;
							}
							.progress-bar-animated {
								-webkit-animation: 1s linear infinite progress-bar-stripes;
								animation: 1s linear infinite progress-bar-stripes;

								@media (prefers-reduced-motion: reduce) {
									-webkit-animation: 1s linear infinite progress-bar-stripes;
									animation: 1s linear infinite progress-bar-stripes;
								}
							}
						}
					}
				}
			}
			
			.ems-analysis-card-body.filter-closed {
			
				.analysis-filter-container {
					width: @ems-analysis-filter-toggle-container-width !important;
					min-width: 0px;
					
					.analysis-filter-content-container {
						display: none;
					}
					.analysis-filter-toggle-container {
					
						cursor: default;
						
						.open-close-filter {
							.fa-arrow-alt-circle-left {
								display: none;
							}
							.fa-arrow-alt-circle-right {
								display: inline-block;
							}
						}	
					}	
				}
			}
			
			@media (max-width: @viewport-md-max) {
				
				.ems-analysis-card-body {
								
					.analysis-filter-container {
						width: @ems-analysis-filter-container-initial-width-md;
					}					
				}				
			}
			
			@media (max-width: @viewport-sm-max) {
			
				.card-header {
					.card-title {
						.open-close-filter-sm {
							cursor: pointer;
							
							.fa-filter {
								font-size: 22px;
							}
							.fa-arrow-alt-circle-down {
								font-size: 22px;
								display: inline-block;
								
							}
							.fa-arrow-alt-circle-up {
								font-size: 22px;
								display: none;
							}
						}	
					}
					.ems-analysis-card-header-toolbar {
						.analysis-card-header-tool.dropdown.download-in-progress {
							
							span.dropdown-toggle {						

								.fa-spinner {
									display: none;
								}
							}
							ul.dropdown-menu.show {
								display: block;

								> li {
									.accordion {
										.accordion-item {
											h3.accordion-header {
												.accordion-button,
												.accordion-button.collapsed	{
												
													.fa-download {
														display: none;
													}
													.fa-spinner {
														display: inline-block;
													}
													.fa-caret-down,
													.fa-caret-up {
														display: none;
													}

													&:hover {
														background-color: @header-menu-dropdown-item-background-color;
														border-bottom: @header-menu-dropdown-item-border-bottom;
														color: @header-menu-dropdown-item-color;
														font-family: @header-menu-dropdown-item-font-family;
														font-weight: @header-menu-dropdown-item-font-weight;
														font-size: @header-menu-dropdown-item-font-size;
														border-left: 0px;
														padding: @header-menu-dropdown-item-padding;
														padding-top: 4.5px;
														padding-bottom: 4.5px;
														cursor: default;	
													
														.fas {
															color: @header-menu-dropdown-item-color;
														}	
													}
												}
												.accordion-button:not(.collapsed) {
													background-color: @header-menu-dropdown-item-background-color;
													border-bottom: @header-menu-dropdown-item-border-bottom;
													color: @header-menu-dropdown-item-color;
													font-family: @header-menu-dropdown-item-font-family;
													font-weight: @header-menu-dropdown-item-font-weight;
													font-size: @header-menu-dropdown-item-font-size;
													border-left: 0px;
													padding: @header-menu-dropdown-item-padding;
													padding-top: 4.5px;
													padding-bottom: 4.5px;
													cursor: default;	
												
													.fas {
														color: @header-menu-dropdown-item-color;
													}	
												}
											}
											.accordion-collapse {
												display: none;
											}			
										}
									}
								}
							}
						}
					}
				}	
					
				.ems-analysis-card-body {
					.analysis-filter-container {
						width: 100% !important;
						max-width: 100%;
						min-width: 0px;
						height: 0;
						
						.analysis-filter-toggle-container {
							display: none;
						}
						
						.analysis-filter-headline-sm {
							font-family: @ems-analysis-filter-headline-sm-font-family;
							font-weight: @ems-analysis-filter-headline-sm-font-weight;
							font-size: @ems-analysis-filter-headline-sm-font-size;
							color: @ems-analysis-filter-headline-sm-color;
							padding-bottom: 1rem;
						}
					}
					
					.analysis-main-card {
						.analysis-main-card-body {
							height: auto;
							
							.analysis-main-card-diagram-and-details {
								height: auto;
								overflow: auto;
								
								.analysis-diagram-card-body {
									height: auto;
								}
								.analysis-diagram-details-table-container {
									height: auto;
									
									.diagram-details-resize-buttons {
										display: none !important;
									}	
								}		
							}	
						}
					}
				}	
			}
		}
		.ems-analysis-card.filter-opened-sm {
			
			@media (max-width: @viewport-sm-max) {
			
				.card-header {
					.card-title {
						.open-close-filter-sm {							
							.fa-arrow-alt-circle-down {
								display: none;
							}
							.fa-arrow-alt-circle-up {
								display: inline-block;
							}
						}	
					}
				}	
				.ems-analysis-card-body {
					.analysis-filter-container {
						height: auto;
					}
				}
			}	
		}
		
		.ems-analysis-card.fullscreen {
			position: absolute;
			top: 0px;
			z-index: 1040;
			height: 100vh;
			width: 100%;
			left: 0px;
			//max-width: @viewport-xxl-max; // und dann ohne left 0
						
			.card-header {
				.ems-analysis-card-header-toolbar {
					.analysis-card-header-tool {
						.min-max-analysis {
							.fa-expand-alt {
								display: none;
							}
							.fa-compress-alt {
								display: inline-block;
							}
						}
					}
				}
			}
		}
		
		.modal-filter-accordion {
			.accordion-item {
				.accordion-body {
					padding: 1rem 0 0 0;
					border-bottom: 2px solid @color-1;
				}
			}
		}
	}
	
	
	@media (min-width: @viewport-sm-min) {
		#modalDialogueObjectTree {
			.modal-dialog {
				max-width: @ems-object-jstree-modal-dialogue-max-width;
			}
		}	
	}

	.color-fetcher,
	.color-ANALYSIS_PLOT-1,
	.color-ANALYSIS_PLOT-2,
	.color-ANALYSIS_PLOT-3,
	.color-ANALYSIS_PLOT-4,
	.color-ANALYSIS_PLOT-5,
	.color-ANALYSIS_PLOT-6,
	.color-ANALYSIS_PLOT-7,
	.color-ANALYSIS_PLOT-8,
	.color-ANALYSIS_PLOT-9 {
		display: none !important;
		width: 0px;
		height: 0px;
		position: absolute;
		left: -99999px;
		top: -99999px;
		z-index: -99999;
	}
	.color-ANALYSIS_PLOT-1 {
		color: @color-ANALYSIS_PLOT-1;
	}
	.color-ANALYSIS_PLOT-2 {
		color: @color-ANALYSIS_PLOT-2;
	}
	.color-ANALYSIS_PLOT-3 {
		color: @color-ANALYSIS_PLOT-3;
	}
	.color-ANALYSIS_PLOT-4 {
		color: @color-ANALYSIS_PLOT-4;
	}
	.color-ANALYSIS_PLOT-5 {
		color: @color-ANALYSIS_PLOT-5;
	}
	.color-ANALYSIS_PLOT-6 {
		color: @color-ANALYSIS_PLOT-6;
	}
	.color-ANALYSIS_PLOT-7 {
		color: @color-ANALYSIS_PLOT-7;
	}
	.color-ANALYSIS_PLOT-8 {
		color: @color-ANALYSIS_PLOT-8;
	}
	.color-ANALYSIS_PLOT-9 {
		color: @color-ANALYSIS_PLOT-9;
	}
	
}
@ems-analysis: ems-analysis;	