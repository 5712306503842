@font-face {
    font-family: 'OpenSans';
    src: 
        url('../../fonts/OpenSans.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSansBold';
    src: 
        url('../../fonts/OpenSans-bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}


	// standard PoCo company colors
/***/@color-1: #ffdd00; //gelb
/***/@color-2: #009ee0; //blau
/***/@color-3: #009ee0; //blau
/***/@color-4: #005d84; //dunkelblau - Text hover, Text auf gelb
/***/@color-5: #edf4f5; //blaugrau hell - BG Flächen, Tabellen
	 
	// standard PoCo grayscaling
/***/@grey-1: #333333; // ex color-a1
	 @grey-2: #434343; // ex color-a2
	 @grey-3: #757575; // ex color-a4a
	 @grey-4: #b9b9b9; // ex color-a4
	 @grey-5: #dedede; // ex color-a5 und color-b3
	 @grey-6: #eaeaea; // ex color-a6
	 @grey-7: #f2f2f2; // ex color-a7 und color-b4
	 @grey-8: #f8f8f8;
	 
	 @grey-9: #e5e5e5; // Widget Borders


	 @white: #FFFFFF;
	 @black: #000000;
	 @red:   #FF0000;
	 @green: #32A817;


	 @inactive-color: #bbbbbb;
	 @inactive-bg-color: #dddddd;
	 @inactive-border-color: #d0d0d0;

	 @shadowed-element-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);


	 @alert-danger-color: #ff0000;
	 @alert-danger-txt-color: @alert-danger-color;
	 @alert-danger-bg-color: #ffeadf;
	 @alert-warning-color: #ea7000;
	 @alert-warning-txt-color: @alert-warning-color;
	 @alert-warning-bg-color: #fff8ea;
	 @alert-info-color: @grey-4;
	 @alert-info-txt-color: @color-3;
	 @alert-info-bg-color: @white;
	 @alert-password-weak-color: #ec785b;
	 @alert-password-weak-bg-color: #ffe7e1;
	 @alert-password-medium-color: #ffd04a;
	 @alert-password-medium-bg-color: #fffaea;
	 @alert-password-strong-color: #72b972;
	 @alert-password-strong-bg-color: #e0f9e0;

	 @popover-border-radius: 0px;
	 @popover-header-font-family: @font-bold;
	 @popover-header-font-weight: @font-bold-weight;
	 @popover-header-font-size: 18px;
	 @popover-body-font-family: @font-regular;
	 @popover-body-font-weight: @font-regular-weight;
	 @popover-body-font-size: @font-size;


	 @font-regular: 'OpenSans', Helvetica, Arial, sans-serif;
	 @font-regular-weight: normal;
	 @font-bold: 'OpenSansBold', Helvetica, Arial, sans-serif;
	 @font-bold-weight: normal;
	 @font-color: @grey-1;
	 @font-size: 16px;

	 @h1-color: @color-3;
	 @h1-font-family: @font-regular;
	 @h1-font-weight: @font-regular-weight;
	 @h1-font-size: 36px;
	 @h1-border-bottom: 0px solid @black;
	 @h2-color: @color-3;
	 @h2-font-family: @font-regular;
	 @h2-font-weight: @font-regular-weight;
	 @h2-font-size: 30px;
	 @h2-border-bottom: 0px solid @black;
	 @h3-color: @color-3;
	 @h3-font-family: @font-bold;
	 @h3-font-weight: @font-bold-weight;
	 @h3-font-size: 18px;
	 @h3-border-bottom: 1px solid @grey-5;
	 @highlight-color: @color-2;
	 @required-color: @color-2;
	 @footnote-color: @grey-4;



	// body
	 @cockpit-bg-type: bgcolor; /* bgcolor || bgimage */
	 @process-bg-type: bgcolor; /* bgcolor || bgimage */
	 @body-background-start-color: @color-5;
	 @body-background-end-color: @body-background-start-color;


	// base-layout
	 @cage-width: 100%;
	 @cockpit-cage-background-color: @white;
	 @cockpit-main-panorama-background-color: @color-1;


	// header
	 @header-width: 100%;
	 @header-left: 0;
	 @header-right: 0;
	 @header-height: 80px;
	 @header-height-sm: 59px;
	 @header-background-color: @white;
	 @header-border-bottom: 5px solid @color-3;
	 @header-cage-width: 100%;
	 @header-cage-max-width: @viewport-xxl-max;
	 @header-shadowed: rgba(0, 30, 80, 0.16) 0px 3px 6px, rgba(0, 30, 80, 0.23) 0px 3px 6px;

/***/@header-logo-width: 108px;
/***/@header-logo-height: 61px;
/***/@header-logo-margin-top: 6px;
	 @header-logo-margin-left: 16px;
/***/@header-logo-width-sm: 72px;
/***/@header-logo-height-sm: 41px;
/***/@header-logo-margin-top-sm: 6px;
	 @header-logo-margin-left-sm: 16px;

	 @header-context-height: 50px;
	 @header-context-background-color: @color-3;
	 @header-context-cage-width: 100%;
	 @header-context-cage-max-width: @viewport-xxl-max;

	 @header-context-toggle-border: 2px solid @white;
	 @header-context-toggle-border-radius: 3px;
	 @header-context-toggle-background-color: @color-1;
	 @header-context-toggle-background-color-hover: @color-2;
	 @header-context-toggle-bars-color: @white;
	 @header-context-toggle-bars-color-hover: @white;
	 @header-context-lines-color: @white;
	 @header-context-lines-font-size: 12px;
	 @header-context-lines-font-family: @font-regular;
	 @header-context-lines-font-weight: @font-regular-weight;

	 @header-menu-margin-top: 23px;
	 @header-menu-icon-color: @grey-1;
	 @header-menu-icon-color-hover: @color-3;
	 @header-menu-postbox-badge-background-color: @color-3;
	 @header-menu-postbox-badge-color: @white;
	 @header-menu-postbox-badge-font-family: @font-bold;
	 @header-menu-postbox-badge-font-weight: @font-bold-weight;
	 @header-menu-postbox-badge-font-size: 13px;
	 @header-menu-logout-border-radius: 3px;
	 @header-menu-logout-background-color: @color-1;
/***/@header-menu-logout-icon-color: @color-2;
	 @header-menu-logout-font-family: @font-regular;
	 @header-menu-logout-font-weight: @font-regular-weight;
	 @header-menu-logout-font-size: 20px;
	 @header-menu-logout-color: @color-2;
	 @header-menu-logout-background-color-hover: @color-2;
/***/@header-menu-logout-icon-color-hover: @color-1;
/***/@header-menu-logout-color-hover: @color-1;
	 @header-menu-margin-top-sm: 14px;

	 @header-menu-dropdown-menu-right: 219px;
	 @header-menu-dropdown-menu-right-sm: 120px;
	 @header-menu-dropdown-menu-right-xs: 16px;
	 @header-menu-dropdown-menu-border: 0px solid @black;
	 @header-menu-dropdown-menu-box-shadow: @shadowed-element-box-shadow;
@header-menu-dropdown-item-background-color: @white;
@header-menu-dropdown-item-border-bottom: 1px solid @white;  
	 @header-menu-dropdown-item-color: @color-2;
	 @header-menu-dropdown-item-font-family: @font-regular;
	 @header-menu-dropdown-item-font-weight: @font-regular-weight;
	 @header-menu-dropdown-item-font-size: @font-size;
	 @header-menu-dropdown-item-padding: 3px 16px;
@header-menu-dropdown-item-background-color-hover: @color-1;
@header-menu-dropdown-item-border-bottom-hover: 1px solid @color-1;  
@header-menu-dropdown-item-color-hover: @color-4;
	 @header-menu-dropdown-item-font-family-hover: @font-regular;
	 @header-menu-dropdown-item-font-weight-hover: @font-regular-weight;
	 @header-menu-dropdown-item-font-size-hover: @font-size;
@header-menu-dropdown-item-border-left-hover: 6px solid @color-1;
	 @header-menu-dropdown-item-padding-hover: 3px 16px 3px 10px;


	//footer
	 @footer-width: 100%;
	 @footer-background-color: @color-1;
	 @footer-top-nav-width: 100%;
	 @footer-top-nav-background-color: @grey-6;
	 @footer-top-nav-cage-width: 100%;
	 @footer-top-nav-cage-max-width: @viewport-xxl-max;
	 @footer-top-nav-cage-text-align: center;
	 @footer-bottom-nav-width: 100%;
	 @footer-bottom-nav-background-color: @color-2;
	 @footer-bottom-nav-cage-width: 100%;
	 @footer-bottom-nav-cage-max-width: @viewport-xxl-max;
	 @footer-bottom-nav-cage-text-align: center;

	 @footer-top-nav-link-font-family: @font-regular;
	 @footer-top-nav-link-font-weight: @font-regular-weight;
	 @footer-top-nav-link-font-size: 22px;
	 @footer-top-nav-link-color: @color-2;
	 @footer-top-nav-link-text-decoration: none;
	 @footer-top-nav-link-after-color: @color-2;
	 @footer-top-nav-link-font-size-sm: 18px;

	 @footer-top-nav-link-font-family-hover-focus: @footer-top-nav-link-font-family;
	 @footer-top-nav-link-font-weight-hover-focus: @footer-top-nav-link-font-weight;
	 @footer-top-nav-link-font-size-hover-focus: @footer-top-nav-link-font-size;
/***/@footer-top-nav-link-color-hover-focus: @color-4;
/***/@footer-top-nav-link-text-decoration-hover-focus: none;
	 @footer-top-nav-link-font-size-sm-hover-focus: @footer-top-nav-link-font-size-sm;

	 @footer-bottom-nav-link-padding: 7px 12px;
	 @footer-bottom-nav-link-font-family: @font-regular;
	 @footer-bottom-nav-link-font-weight: @font-regular-weight;
	 @footer-bottom-nav-link-font-size: 16px;
/***/@footer-bottom-nav-link-color: @white;
	 @footer-bottom-nav-link-text-decoration: none;
	 @footer-bottom-nav-link-padding-sm: 0px 0px 0px 12px;
	 @footer-bottom-nav-link-font-size-sm: 16px;

	 @footer-bottom-nav-link-font-family-hover-focus: @footer-bottom-nav-link-font-family;
	 @footer-bottom-nav-link-font-weight-hover-focus: @footer-bottom-nav-link-font-weight;
	 @footer-bottom-nav-link-font-size-hover-focus: @footer-bottom-nav-link-font-size;
/***/@footer-bottom-nav-link-color-hover-focus: @color-4;
/***/@footer-bottom-nav-link-text-decoration-hover-focus: none;
	 @footer-bottom-nav-link-font-size-sm-hover-focus: @footer-bottom-nav-link-font-size-sm;


	// main
	 @main-padding-top: @header-height;
	 @main-padding-top-sm: @header-height-sm;

	 @main-process-top-stripe-width: 100%;
	 @main-process-top-stripe-height: 42px;
	 @main-process-top-stripe-background-color: rgba(255, 221, 0, 0.4);
	 @main-process-top-stripe-height-md: 49px;

	 @main-process-cage-width: 100%;
	 @main-process-cage-max-width: @viewport-xxl-max; 

	 @main-cockpit-panorama-cage-width: 100%;
	 @main-cockpit-panorama-cage-max-width: @viewport-xxl-max;
	 @main-cockpit-panorama-cage-min-height: 300px;
	 @main-cockpit-panorama-cage-min-height-md: 240px;

	 @main-login-and-context-width: 33.333333%;
	 @main-login-and-context-width-lg: 50%;
	 @main-login-and-context-width-md: 50%;
	 @main-login-and-context-width-sm: 100%;
	 @main-login-and-context-order: 1;
	 @main-panorama-image-width: 66.666667%;
	 @main-panorama-image-width-lg: 50%;
	 @main-panorama-image-width-md: 50%;
	 @main-panorama-image-order: 2;
	 @main-panorama-claim-font-family: @font-bold;
	 @main-panorama-claim-font-weight: @font-bold-weight;
	 @main-panorama-claim-font-size: 47px;
	 @main-panorama-claim-color: @white;
	 @main-panorama-claim-text-shadow: 1px 1px 9px rgba(0, 0, 0, 0.8);
	 @main-panorama-claim-font-size-lg: 35px;
	 @main-panorama-claim-font-size-md: 29px;


	// main-login card
	 @main-login-card-border-width: 0px;
	 @main-login-card-border: @main-login-card-border-width solid @black;
	 @main-login-card-border-radius: 0px;
	 @main-login-card-background-color: @color-1;
	 @main-login-card-margin: 12px 16px 0px 16px;
	 @main-login-card-margin-sm: 0px;
	 @main-login-card-padding-left: 64px;
	 @main-login-card-header-background-color: @color-1;
	 @main-login-card-header-border-bottom: 0px solid @black;
	 @main-login-card-header-caret-width: 25px;
	 @main-login-card-header-caret-color: @white;
	 @main-login-card-title-font-family: @font-regular;
	 @main-login-card-title-font-weight: @font-regular-weight;
	 @main-login-card-title-font-size: 36px;
	 @main-login-card-title-font-size-md: 29px;
	 @main-login-card-title-font-size-sm: 20px;
	 @main-login-card-title-color: @color-2;
	 @main-login-card-title-border-bottom: 0px solid @black;
	 @main-login-card-body-background-color: @color-1;
	 @main-login-card-background-color-sm: @color-1;	
	 @main-login-card-header-background-color-sm: @main-login-card-background-color-sm;
	 @main-login-card-header-caret-background-color-sm: rgba(0,0,0,0.07);
	 @main-login-card-body-background-color-sm: @main-login-card-background-color-sm;
	 
	 @main-login-button-primary-color: @button-primary-color;
	 @main-login-button-primary-border: @button-primary-border;
	 @main-login-button-primary-background-color: @button-primary-background-color;
	 @main-login-button-border-radius: @button-border-radius;
	 @main-login-button-primary-font-family: @button-primary-font-family;
	 @main-login-button-primary-font-weight: @button-primary-font-weight;
	 @main-login-button-primary-font-size: @button-primary-font-size;
	 @main-login-button-primary-padding: @button-primary-padding;
/***/@main-login-button-primary-color-hover-focus: @color-2;
/***/@main-login-button-primary-border-hover-focus: 2px solid @color-2;
/***/@main-login-button-primary-background-color-hover-focus: @white;								
	
     @main-login-card-link-font-family: @font-regular;
	 @main-login-card-link-font-weight: @font-regular-weight;
	 @main-login-card-link-font-size: 14px;
/***/@main-login-card-link-color: @color-4;
	 @main-login-card-link-text-decoration: none;
/***/@main-login-card-link-color-hover-focus: @color-2;
/***/@main-login-card-link-text-decoration-hover-focus: none;


	// cockpit-cards
	 @cockpit-cards-container-background-color: @white;
	 @cockpit-cards-container-padding-bottom: 32px;
	 @cockpit-cards-container-padding-bottom-sm: 0px;
	 @cockpit-cards-cage-width: 100%;
	 @cockpit-cards-cage-max-width: @viewport-xxl-max;
	 @cockpit-cards-cage-justify-content: center;
	 @cockpit-cards-cage-border-bottom-sm: 1px solid @grey-6;

/***/@cockpit-card-border-width: 1px;
/***/@cockpit-card-border: @cockpit-card-border-width solid @grey-9;
	 @cockpit-card-border-radius: 0px;
/***/@cockpit-card-background-color: @color-5;
	 @cockpit-card-margin: 32px 16px 0px 16px;
	 @cockpit-card-width: calc(~'33.333333% - 16px - 16px');
	 @cockpit-card-width-lg: calc(~'50% - 16px - 16px');
	 @cockpit-card-width-md: calc(~'50% - 16px - 16px');
	 @cockpit-card-width-sm: 100%;
	 @cockpit-card-margin-sm: 0px;
	 @cockpit-card-padding-left: 64px;
/***/@cockpit-card-header-background-color: transparent;
	 @cockpit-card-header-border-bottom: 0px solid @black;
	 @cockpit-card-header-caret-width: 25px;
	 @cockpit-card-header-caret-color: @white;
/***/@cockpit-card-title-font-family: @font-regular;
/***/@cockpit-card-title-font-weight: @font-regular-weight;
	 @cockpit-card-title-font-size: 36px;
	 @cockpit-card-title-font-size-md: 29px;
	 @cockpit-card-title-font-size-sm: 20px;
	 @cockpit-card-title-color: @color-2;
	 @cockpit-card-title-border-bottom: 0px solid @black;
/***/@cockpit-card-body-background-color: transparent;
	 @cockpit-card-body-font-size: 18px;
	 @cockpit-card-body-color: @font-color;

	// cockpit-cards sm uneven (=nth-child(2n-1))
	 @cockpit-card-background-color-sm-uneven: @grey-7;	
	 @cockpit-card-header-background-color-sm-uneven: @cockpit-card-background-color-sm-uneven;
	 @cockpit-card-header-caret-background-color-sm-uneven: @grey-3;
	 @cockpit-card-body-background-color-sm-uneven: @cockpit-card-background-color-sm-uneven;

	// cockpit-cards sm even (=nth-child(2n))
	 @cockpit-card-background-color-sm-even: @white;
	 @cockpit-card-header-background-color-sm-even: @cockpit-card-background-color-sm-even;
	 @cockpit-card-header-caret-background-color-sm-even: @grey-4;
	 @cockpit-card-body-background-color-sm-even: @cockpit-card-background-color-sm-even;


	// cockpit-card product-calculator - customer type radios
	 @cockpit-card-product-calculator-radio-customer-type-border-radius: 3px;
	 @cockpit-card-product-calculator-radio-customer-type-border: 1px solid @white;
	 @cockpit-card-product-calculator-radio-customer-type-background-color: @grey-6;
	 @cockpit-card-product-calculator-radio-customer-type-font-family: @font-regular;
	 @cockpit-card-product-calculator-radio-customer-type-font-weight: @font-regular-weight;
	 @cockpit-card-product-calculator-radio-customer-type-font-size: 18px;
	 @cockpit-card-product-calculator-radio-customer-type-color: @color-2;
	 @cockpit-card-product-calculator-radio-customer-type-background-color-hover-focus: @color-1;
	 @cockpit-card-product-calculator-radio-customer-type-color-hover-focus: @cockpit-card-product-calculator-radio-customer-type-color;
	 @cockpit-card-product-calculator-radio-customer-type-checked-background-color: @color-1;
	 @cockpit-card-product-calculator-radio-customer-type-checked-color: @color-2;
	 @cockpit-card-product-calculator-radio-customer-type-checked-background-color-hover-focus: @cockpit-card-product-calculator-radio-customer-type-checked-background-color;
	 @cockpit-card-product-calculator-radio-customer-type-checked-color-hover-focus: @cockpit-card-product-calculator-radio-customer-type-checked-color; 

	// cockpit-card product-calculator - branches radios
	 @cockpit-card-product-calculator-radio-branches-border-radius: 3px;
	 @cockpit-card-product-calculator-radio-branches-border: 1px solid @white;
	 @cockpit-card-product-calculator-radio-branches-background-color: @grey-6;
	 @cockpit-card-product-calculator-radio-branches-font-family: @font-regular;
	 @cockpit-card-product-calculator-radio-branches-font-weight: @font-regular-weight;
	 @cockpit-card-product-calculator-radio-branches-font-size: 18px;
	 @cockpit-card-product-calculator-radio-branches-color: @color-2;
	 @cockpit-card-product-calculator-radio-branches-background-color-hover-focus: @color-1;
	 @cockpit-card-product-calculator-radio-branches-color-hover-focus: @cockpit-card-product-calculator-radio-branches-color;
	 @cockpit-card-product-calculator-radio-branches-checked-background-color: @color-1;
	 @cockpit-card-product-calculator-radio-branches-checked-color: @color-2;
	 @cockpit-card-product-calculator-radio-branches-checked-background-color-hover-focus: @cockpit-card-product-calculator-radio-branches-checked-background-color;
	 @cockpit-card-product-calculator-radio-branches-checked-color-hover-focus: @cockpit-card-product-calculator-radio-branches-checked-color; 

	 @cockpit-card-product-calculator-input-container-label-font-family: @font-regular;
	 @cockpit-card-product-calculator-input-container-label-font-weight: @font-regular-weight;
	 @cockpit-card-product-calculator-input-container-label-font-size: @font-size;
	 @cockpit-card-product-calculator-input-container-label-color: @font-color;

	 @cockpit-card-product-calculator-consumption-button-height: 40px;
	 @cockpit-card-product-calculator-consumption-button-flex: 20%;
	 @cockpit-card-product-calculator-consumption-button-border-right: 1px solid @white;
	 @cockpit-card-product-calculator-consumption-button-border-radius: 3px;
	 @cockpit-card-product-calculator-consumption-button-background-color: @grey-6;
	 @cockpit-card-product-calculator-consumption-button-background-color-hover: @color-1;
	 @cockpit-card-product-calculator-consumption-button-background-color-active: @color-1;


	// context menu
	 @context-menu-login-name-background-color: @color-1;
	 @context-menu-login-name-font-family: @font-regular;
	 @context-menu-login-name-font-weight: @font-regular-weight;
	 @context-menu-login-name-color: @color-2;
	 @context-menu-login-name-font-size: 28px;
	 @context-menu-login-name-padding: 14px 16px;
	 @context-menu-login-name-font-size-md: 23px;
	 @context-menu-login-name-padding-md: 7px 16px;
	 @context-menu-login-name-font-size-sm: 20px;
	 @context-menu-login-name-padding-sm: 5px 16px;

	 @context-menu-accordion-button-background-color: @color-3;
	 @context-menu-accordion-button-background-color-hover-focus: @color-2;
	 @context-menu-accordion-button-border-bottom: 1px solid @color-1;
	 @context-menu-accordion-button-border-bottom-hover-focus: @context-menu-accordion-button-border-bottom;
	 @context-menu-accordion-button-border-bottom-process-no-toggle: 1px solid @color-3;
	 @context-menu-level-icon-color: @color-1;
	 @context-menu-level-icon-color-hover-focus: @context-menu-level-icon-color;
	 @context-menu-level-icon-color-process-no-toggle: @grey-4;
	 @context-menu-label-font-family: @font-regular;
	 @context-menu-label-font-weight: @font-regular-weight;
	 @context-menu-label-font-size: 12px;
	 @context-menu-label-color: @white;
	 @context-menu-label-color-hover-focus: @context-menu-label-color;
	 @context-menu-value-font-family: @font-regular;
	 @context-menu-value-font-weight: @font-regular-weight;
	 @context-menu-value-font-size: 18px;
	 @context-menu-value-color: @white;
	 @context-menu-value-color-hover-focus: @context-menu-value-color;

	 @context-menu-entry-background-color: @grey-7;
	 @context-menu-entry-border-bottom: 1px solid @grey-5;
	 @context-menu-entry-font-family: @font-regular;
	 @context-menu-entry-font-weight: @font-regular-weight;
	 @context-menu-entry-font-size: 16px;
	 @context-menu-entry-color: @grey-2;
	 @context-menu-entry-padding: 10px 16px 8px 56px;
	 @context-menu-entry-background-color-hover-focus: @white;
	 @context-menu-entry-border-bottom-hover-focus: @context-menu-entry-border-bottom;
	 @context-menu-entry-font-family-hover-focus: @context-menu-entry-font-family;
	 @context-menu-entry-font-weight-hover-focus: @context-menu-entry-font-weight;
	 @context-menu-entry-color-hover-focus: @color-2;
	 @context-menu-entry-active-background-color: @white;
	 @context-menu-entry-active-font-family: @font-bold;
	 @context-menu-entry-active-font-weight: @font-bold-weight;
	 @context-menu-entry-active-color: @color-2;
	 @context-menu-entry-active-background-color-hover-focus: @context-menu-entry-active-background-color;
	 @context-menu-entry-active-font-family-hover-focus: @context-menu-entry-active-font-family;
	 @context-menu-entry-active-font-weight-hover-focus: @context-menu-entry-active-font-weight;
	 @context-menu-entry-active-color-hover-focus: @context-menu-entry-active-color;

	 @context-menu-accordion-item-last-child-border-bottom-sm: 15px solid @grey-5;


	// process
	 @main-context-and-process-std-viewport-min-width: calc(~'@{viewport-xs-min} + @{main-process-max-width} + @{viewport-xs-min}');
	 @main-process-top-image-min-height-md: 200px; 
	 @main-process-max-width: 960px;
	 @main-process-background-color: transparent;

	 @main-context-background-color-md: @color-3;

	 @main-process-card-border: 0px solid @black;
	 @main-process-card-border-radius: 0px;
	 @main-process-card-background-color: @white;
	 @main-process-card-header-background-color: @white;
	 @main-process-card-header-border-bottom: 1px solid @white;
	 @main-process-card-title-font-family: @font-regular;
	 @main-process-card-title-font-weight: @font-regular-weight;
	 @main-process-card-title-color: @color-2;
	 @main-process-card-title-font-size: 28px;
	 @main-process-card-title-padding-top: 11px;
	 @main-process-card-body-background-color: @white;
	 @main-process-card-body-border-radius: 0px; 
	 @main-process-card-title-font-size-sm: 22px;
	 @main-process-card-footer-background-color: @white;
	 @main-process-card-footer-border-top: 0px solid @black;
	 @main-process-card-footer-border-radius: 0px;

	 @hr-background-color: @grey-5;

	// card in card
	 @main-process-card-in-card-background-color: @white;
	 @main-process-card-in-card-border: 0px solid @black;
	 @main-process-card-in-card-border-radius: 0px;
	 @main-process-card-in-card-header-background-color: @color-3;
	 @main-process-card-in-card-header-border-bottom: 0px solid @black;
	 @main-process-card-in-card-title-font-family: @font-regular;
	 @main-process-card-in-card-title-font-weight: @font-regular-weight;
	 @main-process-card-in-card-title-color: @white;
	 @main-process-card-in-card-title-font-size: 20px;
	 @main-process-card-in-card-body-background-color: @white;
	 @main-process-card-in-card-body-border-radius: 0px;
	 @main-process-card-in-card-footer-background-color: @white;
	 @main-process-card-in-card-footer-border-top: 0px solid @black;
	 @main-process-card-in-card-footer-border-radius: 0px; 

	// steps
	 @steps-container-margin: 7px 36px 0px 0px;
	 @step-width: 11px;
	 @step-height: 11px;
	 @step-background-color: @color-3;
	 @step-border: 1px solid @color-3;
	 @step-background-color-active: @color-1;
	 @step-border-active: 1px solid @color-1;


	// form labels
	 @label-font-family: @font-bold;
	 @label-font-weight: @font-bold-weight;
	 @label-color: @font-color;
	 @label-font-size: 16px;
	 @confirm-page-label-font-family: @font-bold;
	 @confirm-page-label-font-weight: @font-bold-weight;
	 @confirm-page-label-color: @font-color;
	 @confirm-page-label-font-size: 16px;
	 @confirm-page-value-font-family: @font-regular;
	 @confirm-page-value-font-weight: @font-regular-weight;
	 @confirm-page-value-color: @font-color;
	 @confirm-page-value-font-size: 16px;

	// form inputs
	 @form-control-placeholder-color: @grey-3;
	 @form-control-background-color: @white;
	 @form-control-color: @font-color;
	 @form-control-font-family: @font-regular;
	 @form-control-font-weight: @font-regular-weight;
	 @form-control-font-size: 16px;
	 @form-control-border: 1px solid @grey-4;
	 @form-control-border-radius: 0px;
	 @form-control-font-size-sm: 15px;

	 @form-control-background-color-focus: @white;
	 @form-control-color-focus: @form-control-color;
	 @form-control-font-family-focus: @form-control-font-family;
	 @form-control-font-weight-focus: @form-control-font-weight;
	 @form-control-font-size-focus: @form-control-font-size;
	 @form-control-border-focus: 1px solid @color-3;
	 @form-control-font-size-sm-focus: @form-control-font-size-sm;

	 @form-control-readonly-background-color: @white;
	 @form-control-readonly-color: @grey-3;
	 @form-control-readonly-border-color: @grey-6;

	 @input-group-text-border: @form-control-border;
	 @input-group-text-color: @font-color;
	 @input-group-text-background-color: @grey-6;

	// form inputs login card
	 @form-control-login-label-color: @grey-1;
	 @form-control-login-label-color-focus: @color-2;
	 @form-control-login-background-color: @color-1;
	 @form-control-login-background-color-focus: @form-control-login-background-color;
	 @form-control-login-border-color: @white;
	 @form-control-login-border-color-focus: @white;
	 @form-control-login-color: @form-control-color;
	 @form-control-login-color-focus: @form-control-color-focus;	

	// form-floating label + input
	 @form-floating-label-color: @form-control-placeholder-color;
	 @form-floating-label-color-focus: @color-2;
	 @form-floating-label-font-size: 18px;
	 @form-floating-label-font-size-sm: 15px;
	 @form-floating-label-font-size-focus: 12px;
/***/@form-floating-form-control-background-color: transparent;
	 @form-floating-form-control-background-color-focus: @form-floating-form-control-background-color;
		
	// selects + autocomplete
	 @bootstrap-select-dropdown-menu-border: 0px solid @black;
@bootstrap-select-dropdown-menu-background-color: @color-5;
	 @bootstrap-select-dropdown-menu-box-shadow: @shadowed-element-box-shadow;
@bootstrap-select-dropdown-item-background-color: @color-5;
@bootstrap-select-dropdown-item-border-bottom: 0px solid @grey-5;
	 @bootstrap-select-dropdown-item-font-family: @font-regular;
	 @bootstrap-select-dropdown-item-font-weight: @font-regular-weight;
	 @bootstrap-select-dropdown-item-font-size: 14px;
	 @bootstrap-select-dropdown-item-color: @color-2;
@bootstrap-select-dropdown-item-background-color-hover-focus: @color-1;
@bootstrap-select-dropdown-item-border-bottom-hover-focus: 0px solid @grey-5;
	 @bootstrap-select-dropdown-item-font-family-hover-focus: @font-regular;
	 @bootstrap-select-dropdown-item-font-weight-hover-focus: @font-regular-weight;
	 @bootstrap-select-dropdown-item-font-size-hover-focus: 14px;
@bootstrap-select-dropdown-item-color-hover-focus: @color-4;
@bootstrap-select-dropdown-item-selected-background-color: @color-1;
@bootstrap-select-dropdown-item-selected-border-bottom: 0px solid @grey-5;
	 @bootstrap-select-dropdown-item-selected-font-family: @font-regular;
	 @bootstrap-select-dropdown-item-selected-font-weight: @font-regular-weight;
	 @bootstrap-select-dropdown-item-selected-font-size: 14px;
@bootstrap-select-dropdown-item-selected-color: @color-4;
	 @bootstrap-select-dropdown-item-selected-background-color-hover-focus: @bootstrap-select-dropdown-item-selected-background-color;
	 @bootstrap-select-dropdown-item-selected-border-bottom-hover-focus: @bootstrap-select-dropdown-item-selected-border-bottom;
	 @bootstrap-select-dropdown-item-selected-font-family-hover-focus: @bootstrap-select-dropdown-item-selected-font-family;
	 @bootstrap-select-dropdown-item-selected-font-weight-hover-focus: @bootstrap-select-dropdown-item-selected-font-weight;
	 @bootstrap-select-dropdown-item-selected-font-size-hover-focus: @bootstrap-select-dropdown-item-selected-font-size;
	 @bootstrap-select-dropdown-item-selected-color-hover-focus: @bootstrap-select-dropdown-item-selected-color;  

	// calendar 
	 @calendar-border: 1px solid @color-3;
	 @calendar-border-radius: 0px;
	 @calendar-background-color: @white;
	 @calendar-box-shadow: @shadowed-element-box-shadow;
	 @calendar-th-prev-background-color: @color-3;
	 @calendar-th-switch-background-color: @color-3;
	 @calendar-th-next-background-color: @color-3;
	 @calendar-th-prev-color: @white;
	 @calendar-th-switch-color: @white;
	 @calendar-th-next-color: @white;
	 @calendar-th-prev-background-color-hover: @grey-2;
	 @calendar-th-switch-background-color-hover: @grey-2;
	 @calendar-th-next-background-color-hover: @grey-2;
	 @calendar-th-prev-color-hover: @white;
	 @calendar-th-switch-color-hover: @white;
	 @calendar-th-next-color-hover: @white;
	 @calendar-th-dow-color: @color-3;
	 @calendar-td-font-size: 14px;
	 @calendar-td-color: @color-3;
	 @calendar-td-color-hover: @color-3;
	 @calendar-td-background-color-hover: @grey-5;
	 @calendar-active-day-color: @white;
	 @calendar-active-day-font-family: @font-bold;
	 @calendar-active-day-font-weight: @font-bold-weight;
	 @calendar-active-day-background-color: @grey-2;

	// file-input
	 @file-input-btn-primary-border: 1px solid @color-3;
	 @file-input-btn-primary-border-hover: 1px solid @color-3;
	 @file-input-btn-default-border: @form-control-border;
	 @file-input-btn-default-border-hover: @form-control-border;
	 @file-input-alert-font-size: 14px;

	// slider
	 @slider-track-background-color: @grey-4;
	 @slider-selection-background-color: @color-3;
	 @slider-handle-background-color: @color-1;
	 @slider-handle-border: 1px solid @color-1;
	 @slider-handle-border-radius: 3px;

	// radios + checkboxes
	 @radio-checkbox-label-font-family: @font-regular;
	 @radio-checkbox-label-font-weight: @font-regular-weight;
	 @radio-checkbox-label-font-size: @font-size;
	 @radio-checkbox-label-color: @font-color;
	 @radio-checkbox-unchecked-border: 1px solid @grey-4;
	 @radio-checkbox-unchecked-background-gradient-start: @grey-6;
	 @radio-checkbox-unchecked-background-gradient-end: @radio-checkbox-unchecked-background-gradient-start;
	 @radio-checkbox-checked-border: 1px solid @grey-4;
	 @radio-checkbox-checked-background-gradient-start: @grey-6;
	 @radio-checkbox-checked-background-gradient-end: @radio-checkbox-checked-background-gradient-start;
	 @radio-bullet-border: 1px solid @color-2;
	 @radio-bullet-background-gradient-start: @color-2;
	 @radio-bullet-background-gradient-end: @radio-bullet-background-gradient-start;
	 @checkbox-tick-color: @color-2;


	// buttons
/***/@button-border-radius: 3px;
	 @button-primary-background-color: @color-3;
	 @button-primary-border: 2px solid @color-3;
	 @button-primary-color: @white;
	 @button-primary-font-family: @font-bold;
	 @button-primary-font-weight: @font-bold-weight;
	 @button-primary-font-size: 18px;
	 @button-primary-padding: 2px 12px 3px 12px;
/***/@button-primary-background-color-hover-focus: @color-1;
/***/@button-primary-border-hover-focus: 2px solid @color-1;
/***/@button-primary-color-hover-focus: @color-3;

	 @button-default-background-color: @white;
/***/@button-default-border: 2px solid @color-1;
/***/@button-default-color: @color-3;
	 @button-default-font-family: @font-bold;
	 @button-default-font-weight: @font-bold-weight;
	 @button-default-font-size: 18px;
	 @button-default-padding: 2px 12px 3px 12px;
/***/@button-default-background-color-hover-focus: @color-3;
/***/@button-default-border-hover-focus: 2px solid @color-3;
/***/@button-default-color-hover-focus: @color-1;

	 @button-close-background-color: @color-1;
	 @button-close-width: 42px;
	 @button-close-height: 34px;
	 @button-close-padding: 8px 0px 0px 0px;
/***/@button-close-color: @color-3;
	 @button-close-border: 0px solid @black;
	 @button-close-border-radius: 0px;
	 @button-close-font-size: 18px;
/***/@button-close-background-color-hover-focus: @color-3;
	 @button-close-border-hover-focus: 0px solid @black;
/***/@button-close-color-hover-focus: @color-1;
	 @button-close-shadowed-box-shadow: @shadowed-element-box-shadow;


	// links
	 @link-font-family: @font-regular;
	 @link-font-weight: @font-regular-weight;
/***/@link-color: @color-2;
	 @link-text-decoration: none;
	 @link-font-family-hover-focus: @font-regular;
	 @link-font-weight-hover-focus: @font-regular-weight;
/***/@link-color-hover-focus: @color-4;
/***/@link-text-decoration-hover-focus: none;

	 @postbox-link-font-family: @font-bold;
	 @postbox-link-font-weight: @font-bold-weight;
	 @postbox-link-color: @link-color;
	 @postbox-link-text-decoration: none;
	 @postbox-link-width: calc(~'100% - 100px');
	 @postbox-icon-color: @link-color;
	 @postbox-link-color-hover-focus: @link-color;
	 @postbox-link-text-decoration-hover-focus: none;
	 @postbox-link-read-font-family: @font-regular;
	 @postbox-link-read-font-weight: @font-regular-weight;
	 @postbox-link-read-color: @link-color;
	 @postbox-icon-read-color: @grey-4;

	 @form-link-font-family: @font-regular;
	 @form-link-font-weight: @font-regular-weight;
	 @form-link-color: @color-2;
	 @form-link-font-size: @font-size;
	 @form-link-text-decoration: none;
	 @form-link-icon-display: inline-block;
	 @form-link-icon-color: @form-link-color;
	 @form-link-font-family-hover-focus: @form-link-font-family;
	 @form-link-font-weight-hover-focus: @form-link-font-weight;
/***/@form-link-color-hover-focus: @color-4;
	 @form-link-font-size-hover-focus: @form-link-font-size;
	 @form-link-text-decoration-hover-focus: none;
/***/@form-link-icon-color-hover-focus: @color-4;


	// meter-value-display
	 @meter-value-display-border-radius: 3px;
	 @meter-value-display-font-size: 18px;
	 @meter-value-display-cell-border: 1px solid @white;
	 @meter-value-display-mv-type-background-color: @grey-5;
	 @meter-value-display-mv-type-color: @grey-2;
	 @meter-value-display-mv-type-font-family: @font-regular;
	 @meter-value-display-mv-type-font-weight: @font-regular-weight;
	 @meter-value-display-mv-pre-comma-not-available-background-color: @color-3;
	 @meter-value-display-mv-pre-comma-not-available-color: @white;
	 @meter-value-display-mv-pre-comma-not-available-font-family: @font-bold;
	 @meter-value-display-mv-pre-comma-not-available-font-weight: @font-bold-weight;
	 @meter-value-display-mv-pre-comma-background-color: @color-3;
	 @meter-value-display-mv-pre-comma-color: @white;
	 @meter-value-display-mv-pre-comma-font-family: @font-bold;
	 @meter-value-display-mv-pre-comma-font-weight: @font-bold-weight;
	 @meter-value-display-mv-comma-background-color: @grey-5;
	 @meter-value-display-mv-comma-color: @grey-2;
	 @meter-value-display-mv-comma-font-family: @font-bold;
	 @meter-value-display-mv-comma-font-weight: @font-bold-weight;
	 @meter-value-display-mv-post-comma-background-color: @grey-5;
	 @meter-value-display-mv-post-comma-color: @grey-2;
	 @meter-value-display-mv-post-comma-font-family: @font-bold;
	 @meter-value-display-mv-post-comma-font-weight: @font-bold-weight;
	 @meter-value-display-mv-unit-background-color: @grey-5;
	 @meter-value-display-mv-unit-color: @grey-2;
	 @meter-value-display-mv-unit-font-family: @font-regular;
	 @meter-value-display-mv-unit-font-weight: @font-regular-weight;


	// tabs
	 @tab-background-color: @white;
	 @tab-border-color: @grey-4;
	 @tab-border: 1px solid  @tab-border-color;
	 @tab-border-radius: 0px;
	 @tab-nav-link-background-color: @grey-8;
	 @tab-nav-link-background-color-hover: @white;
	 @tab-nav-link-font-family: @font-regular;
	 @tab-nav-link-font-weight: @font-regular-weight;
	 @tab-nav-link-font-size: @font-size;
	 @tab-nav-link-color: @color-2;
	 @tab-nav-link-font-family-hover: @tab-nav-link-font-family;
	 @tab-nav-link-font-weight-hover: @tab-nav-link-font-weight;
	 @tab-nav-link-font-size-hover: @tab-nav-link-font-size;
	 @tab-nav-link-color-hover: @tab-nav-link-color;
	 @tab-nav-link-active-background-color: @tab-background-color;
	 @tab-nav-link-active-background-color-hover: @tab-background-color;
	 @tab-nav-link-active-font-family: @font-bold;
	 @tab-nav-link-active-font-weight: @font-bold-weight;
	 @tab-nav-link-active-font-size: @font-size;
	 @tab-nav-link-active-color: @color-2;
	 @tab-nav-link-active-font-family-hover: @tab-nav-link-active-font-family;
	 @tab-nav-link-active-font-weight-hover: @tab-nav-link-active-font-weight;
	 @tab-nav-link-active-font-size-hover: @tab-nav-link-active-font-size;
	 @tab-nav-link-active-color-hover: @tab-nav-link-active-color;


	// modal dialogue
	 @modal-content-border: 0px solid @black;
	 @modal-content-border-radius: 0px;
	 @modal-header-background-color: @color-1;
	 @modal-header-border-bottom: 0px;
	 @modal-title-font-family: @font-bold;
	 @modal-title-font-weight: @font-bold-weight;
	 @modal-title-font-size: 18px;
	 @modal-title-color: @color-2;
	 @modal-body-background-color: @white;
	 @modal-footer-background-color: @white;
	 @modal-footer-border-top: 1px solid @grey-5;


	// tables
	 @table-responsive-border: 0px solid @black;
	 @thead-th-background-color: @grey-6;
	 @thead-th-font-family: @font-bold;
	 @thead-th-font-weight: @font-bold-weight;
	 @thead-font-size: 16px;
	 @thead-th-color: @color-2;
	 @thead-th-border-left: 0px solid @black;
	 @thead-th-border-top: 0px solid @black;
	 @thead-th-border-right: 0px solid @black;
	 @thead-th-border-bottom: 0px solid @black;
	 @tbody-td-border-left: 0px solid @black;
	 @tbody-td-border-top: 0px solid @black;
	 @tbody-td-border-right: 0px solid @black;
	 @tbody-td-border-bottom: 0px solid @black;
	 @tbody-td-background-color-uneven: @white;	/* table-striped td uneven (=nth-child(2n-1)) */
	 @tbody-td-background-color-even: @grey-7;	/* table-striped td even   (=nth-child(2n))   */
	 @tbody-td-collapse-row-background-color: @grey-6;
	 @datatable-postboxTable-message-content-background-color: @grey-7;

	// pagination
	 @pagination-border: 1px solid @grey-4;
	 @pagination-border-radius: 4px;
	 @pagination-background-color: @white;
	 @pagination-font-family: @font-regular;
	 @pagination-font-weight: @font-regular-weight;
	 @pagination-color: @grey-2;
	 @pagination-background-color-hover-focus: @grey-5;
	 @pagination-font-family-hover-focus: @pagination-font-family;
	 @pagination-font-weight-hover-focus: @pagination-font-weight;
	 @pagination-color-hover-focus: @pagination-color;
	 @pagination-active-font-family: @font-bold;
	 @pagination-active-font-weight: @font-bold-weight;
	 @pagination-active-background-color: @color-3;
	 @pagination-active-color: @white;
	 @pagination-active-background-color-hover-focus: @pagination-active-background-color;
	 @pagination-active-font-family-hover-focus: @pagination-active-font-family;
	 @pagination-active-font-weight-hover-focus: @pagination-active-font-weight;
	 @pagination-active-color-hover-focus: @pagination-active-color;
	 @pagination-disabled-background-color: @white;
	 @pagination-disabled-color: @inactive-color;


	// service status
	 @service-status-font-family: @font-bold;
	 @service-status-font-weight: @font-bold-weight;
	 @service-status-font-size: @font-size;
	 @service-status-color: @font-color;
	 @service-status-background-color: @grey-5;
	 @service-status-active-font-family: @service-status-font-family;
	 @service-status-active-font-weight: @service-status-font-weight;
	 @service-status-active-font-size: @service-status-font-size;
	 @service-status-active-color: @white;
	 @service-status-active-background-color: @color-3;
	 @service-status-finished-font-family: @service-status-font-family;
	 @service-status-finished-font-weight: @service-status-font-weight;
	 @service-status-finished-font-size: @service-status-font-size;
	 @service-status-finished-color: @white;
	 @service-status-finished-background-color: @grey-3;


	// tariff calculator card
	 @tariff-calculator-output-row-background-color: @grey-7;
	 @tariff-calculator-output-row-border-bottom: 1px solid @white;
	 @tariff-calculator-output-label-font-family: @font-bold;
	 @tariff-calculator-output-label-font-weight: @font-bold-weight;
	 @tariff-calculator-output-label-font-size: 14px;
	 @tariff-calculator-output-label-color: @grey-1;
	 @tariff-calculator-output-value-font-family: @font-regular;
	 @tariff-calculator-output-value-font-weight: @font-regular-weight;
	 @tariff-calculator-output-value-font-size: 14px;
	 @tariff-calculator-output-value-color: @grey-1;
	  
	 @tariff-calculator-card-accordion-header-background-color: @color-3;
	 @tariff-calculator-card-accordion-header-font-family: @font-bold;
	 @tariff-calculator-card-accordion-header-font-weight: @font-bold-weight;
	 @tariff-calculator-card-accordion-header-font-size: 14px;
	 @tariff-calculator-card-accordion-header-color: @white;
	 @tariff-calculator-card-accordion-body-background-color: @grey-7;

	 @tariff-calculator-radio-customer-type-border: 1px solid @white;
	 @tariff-calculator-radio-customer-type-background-color: @grey-6;
	 @tariff-calculator-radio-customer-type-font-family: @font-regular;
	 @tariff-calculator-radio-customer-type-font-weight: @font-regular-weight;
	 @tariff-calculator-radio-customer-type-font-size: 18px;
	 @tariff-calculator-radio-customer-type-color: @color-2;
	 @tariff-calculator-radio-customer-type-background-color-hover-focus: @color-1;
	 @tariff-calculator-radio-customer-type-color-hover-focus: @tariff-calculator-radio-customer-type-color;
	 @tariff-calculator-radio-customer-type-checked-background-color: @color-1;
	 @tariff-calculator-radio-customer-type-checked-color: @color-2;
	 @tariff-calculator-radio-customer-type-checked-background-color-hover-focus: @tariff-calculator-radio-customer-type-checked-background-color;
	 @tariff-calculator-radio-customer-type-checked-color-hover-focus: @tariff-calculator-radio-customer-type-checked-color;

	 @tariff-calculator-radio-branches-border: 1px solid @white;
	 @tariff-calculator-radio-branches-background-color: @grey-6;
	 @tariff-calculator-radio-branches-font-family: @font-regular;
	 @tariff-calculator-radio-branches-font-weight: @font-regular-weight;
	 @tariff-calculator-radio-branches-font-size: 18px;
	 @tariff-calculator-radio-branches-color: @color-2;
	 @tariff-calculator-radio-branches-background-color-hover-focus: @color-1;
	 @tariff-calculator-radio-branches-color-hover-focus: @tariff-calculator-radio-branches-color;
	 @tariff-calculator-radio-branches-checked-background-color: @color-1;
	 @tariff-calculator-radio-branches-checked-color: @color-2;
	 @tariff-calculator-radio-branches-checked-background-color-hover-focus: @tariff-calculator-radio-branches-checked-background-color;
	 @tariff-calculator-radio-branches-checked-color-hover-focus: @tariff-calculator-radio-branches-checked-color;

	// viewport-max-width to display 2 tariff cards
	 @tariff-display-2-cards-viewport-max-width: 1279px;
	// viewport-max-width to display 1 tariff card
	 @tariff-display-1-card-viewport-max-width: 639px;
	 
	// tariff card
	 @tariff-card-border: 0px solid @black;
	 @tariff-card-border-radius: 0px;
	 @tariff-card-header-background-color: @color-3;
	 @tariff-card-header-border-bottom: 0px solid @black;
	 @tariff-card-title-font-family: @font-bold;
	 @tariff-card-title-font-weight: @font-bold-weight;
	 @tariff-card-title-font-size: 20px;
     @tariff-card-subtitle-font-family: @font-bold;
	 @tariff-card-subtitle-font-size: 16px;
	 @tariff-card-subtitle-font-weight: @font-bold-weight;
     @tariff-card-subtitle-color: @white;
	 @tariff-card-title-color: @white;
	 @tariff-card-title-price-font-family: @tariff-card-title-font-family;
	 @tariff-card-title-price-font-weight: @tariff-card-title-font-weight;
	 @tariff-card-title-price-font-size: 17px;
	 @tariff-card-title-price-color: @tariff-card-title-color;
	 @tariff-card-body-background-color: @grey-6;
	 @tariff-card-image-height: 98px;
	 @tariff-card-image-background-color: @grey-7;
	 @tariff-card-advantages-background-color: @tariff-card-body-background-color;
	 @tariff-card-advantages-icon-color: @color-3;
	 @tariff-card-prices-row-background-color: @grey-7;
	 @tariff-card-prices-row-font-size: 14px;
	 @tariff-card-prices-row-gross-price-font-family: @font-bold;
	 @tariff-card-prices-row-gross-price-font-weight: @font-bold-weight;
	 @tariff-card-prices-row-gross-price-font-size: 22px;
	 @tariff-card-prices-row-gross-price-color: @color-3;
	 @tariff-card-prices-row-saving-font-family: @font-bold;
	 @tariff-card-prices-row-saving-font-weight: @font-bold-weight;
	 @tariff-card-prices-row-saving-font-size: 14px;
	 @tariff-card-prices-row-saving-color: @grey-1;
	 @tariff-card-tariff-details-link-background-color: @grey-6;
	 @tariff-card-tariff-details-row-background-color: @grey-7;
	 @tariff-card-tariff-details-row-font-size: 14px;
	 @tariff-card-tariff-details-row-color: @color-3;
	 @tariff-card-future-tariff-details-row-background-color: @grey-6;
	 @tariff-card-future-tariff-details-row-font-size: 14px;
	 @tariff-card-future-tariff-details-row-color: @grey-2;
	@tariff-card-tariff-additional-details-link-background-color: @grey-6;
	@tariff-card-tariff-additional-details-row-background-color: @grey-7;
	@tariff-card-tariff-additional-details-row-font-size: 14px;
	@tariff-card-tariff-additional-details-row-color: @color-3;
	@tariff-card-future-additional-tariff-details-row-background-color: @grey-6;
	@tariff-card-future-additional-tariff-details-row-font-size: 14px;
	@tariff-card-future-additional-tariff-details-row-color: @grey-2;
	 @tariff-card-footer-background-color: @grey-7;

	 @recommended-tariff-card-border: 0px solid @black;
	 @recommended-tariff-card-border-radius: 0px;
	 @recommended-tariff-card-header-background-color: @color-1;
	 @recommended-tariff-card-header-border-bottom: 0px solid @black;
	 @recommended-tariff-card-title-font-family: @font-bold;
	 @recommended-tariff-card-title-font-weight: @font-bold-weight; 
	 @recommended-tariff-card-title-font-size: 20px;
	 @recommended-tariff-card-title-color: @grey-1;
	 @recommended-tariff-card-subtitle-font-family: @font-bold;
	 @recommended-tariff-card-subtitle-font-weight: @font-bold-weight;
	 @recommended-tariff-card-subtitle-font-size: 16px;
	 @recommended-tariff-card-subtitle-color: @grey-1;
	 @recommended-tariff-card-title-price-font-family: @recommended-tariff-card-title-font-family;
	 @recommended-tariff-card-title-price-font-weight: @recommended-tariff-card-title-font-weight;
	 @recommended-tariff-card-title-price-font-size: 17px;
	 @recommended-tariff-card-title-price-color: @recommended-tariff-card-title-color;
	 @recommended-tariff-card-body-background-color: @grey-6;
	 @recommended-tariff-card-image-background-color: @grey-7;
	 @recommended-tariff-card-advantages-background-color: @recommended-tariff-card-body-background-color;
	 @recommended-tariff-card-advantages-icon-color: @color-2;
	 @recommended-tariff-card-prices-row-background-color: @grey-7;
	 @recommended-tariff-card-prices-row-gross-price-font-family: @font-bold;
	 @recommended-tariff-card-prices-row-gross-price-font-weight: @font-bold-weight;
	 @recommended-tariff-card-prices-row-gross-price-font-size: 22px;
	 @recommended-tariff-card-prices-row-gross-price-color: @color-3;
	 @recommended-tariff-card-prices-row-saving-font-family: @font-bold;
	 @recommended-tariff-card-prices-row-saving-font-weight: @font-bold-weight;
	 @recommended-tariff-card-prices-row-saving-font-size: 14px;
	 @recommended-tariff-card-prices-row-saving-color: @color-3;
	 @recommended-tariff-card-tariff-details-link-background-color: @grey-6;
	 @recommended-tariff-card-tariff-details-row-background-color: @grey-7;
	 @recommended-tariff-card-tariff-details-row-font-size: 14px;
	 @recommended-tariff-card-tariff-details-row-color: @color-3;
	 @recommended-tariff-card-tariff-additional-details-link-background-color: @grey-6;
	 @recommended-tariff-card-tariff-additional-details-row-background-color: @grey-7;
	 @recommended-tariff-card-tariff-additional-details-row-font-size: 14px;
	 @recommended-tariff-card-tariff-additional-details-row-color: @color-3;
	 @recommended-tariff-card-future-tariff-details-row-background-color: @grey-6;
	 @recommended-tariff-card-future-tariff-details-row-font-size: 14px;
	 @recommended-tariff-card-future-tariff-details-row-color: @grey-2;
	 @recommended-tariff-card-footer-background-color: @grey-7;
/***/@recommended-tariff-card-top-tariff-label-top: -10px;
	 @recommended-tariff-card-top-tariff-label-right: 0px;
	 @recommended-tariff-card-top-tariff-label-background-color: @color-3;
/***/@recommended-tariff-card-top-tariff-label-font-family: @font-regular;
/***/@recommended-tariff-card-top-tariff-label-font-weight: @font-regular-weight;
	 @recommended-tariff-card-top-tariff-label-font-size: 12px;
	 @recommended-tariff-card-top-tariff-label-color: @white;
	 @recommended-tariff-card-top-tariff-label-text-transform: uppercase;

	 @tariff-card-placeholder-border: 1px solid @inactive-bg-color;
	 @tariff-card-placeholder-border-radius: 0px;
	 @tariff-card-placeholder-background-color: @white;

	// button more tariffs
/***/@button-more-tariffs-color: @color-3;
	 @button-more-tariffs-border: 2px solid @color-1;
/***/@button-more-tariffs-background-color: @white;
/***/@button-more-tariffs-font-family: @font-bold;
/***/@button-more-tariffs-font-weight: @font-bold-weight;
	 @button-more-tariffs-font-size: 18px;
/***/@button-more-tariffs-color-hover-focus: @color-1;
/***/@button-more-tariffs-border-hover-focus: 2px solid @color-3;
/***/@button-more-tariffs-background-color-hover-focus: @color-3;	

	// base supplier card - tariff calculator
	 @base-supplier-card-body-background-color: @grey-7;
	 @base-supplier-selection-row-background-color: @grey-6;
	 @base-supplier-gross-price-label-font-size: 14px;
	 @base-supplier-gross-price-font-family: @font-bold;
	 @base-supplier-gross-price-font-weight: @font-bold-weight;
	 @base-supplier-gross-price-font-size: 16px;
	 @base-supplier-gross-price-color: @color-3;


	// marginal shopping cart
	 @marginal-card-border: 0px solid @black;
	 @marginal-card-border-radius: 0px;
	 @marginal-card-header-background-color: @color-1;
	 @marginal-card-header-border-bottom: 0px;
	 @marginal-card-header-font-family: @font-regular;
	 @marginal-card-header-font-weight: @font-regular-weight;
	 @marginal-card-header-font-size: 20px;
	 @marginal-card-header-color: @font-color;
	 @marginal-card-body-background-color: @white;
	 @marginal-shopping-cart-header-background-color: @color-3;
	 @marginal-shopping-cart-header-font-family: @font-regular;
	 @marginal-shopping-cart-header-font-weight: @font-regular-weight;
	 @marginal-shopping-cart-header-font-size: 16px;
	 @marginal-shopping-cart-header-color: @white;
/***/@marginal-shopping-cart-sub-header-background-color: @white;
	 @marginal-shopping-cart-sub-header-font-family: @font-bold;
	 @marginal-shopping-cart-sub-header-font-weight: @font-bold-weight;
	 @marginal-shopping-cart-sub-header-font-size: 16px;
	 @marginal-shopping-cart-sub-header-color: @color-2;
	 @marginal-shopping-cart-row-font-size: 14px;
	 @marginal-shopping-cart-row-border-bottom: 1px solid @grey-5;
		